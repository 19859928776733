import { FormControl, Tooltip, Typography, withStyles } from "@material-ui/core";

// Style Definition 
export const TooltipStyle = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.text.white, 
    // color: theme.palette.text.primary,
    // border: '1px solid #dadde9', */
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);


// HtmlTooltip
export const HtmlTooltip = ({ title, text }) => {
  return (
    <TooltipStyle
      title={
        <>
          <Typography color="inherit">{title}</Typography>
          {text}
        </>
      } >
      <FormControl>{title}</FormControl>
    </TooltipStyle>)
}




