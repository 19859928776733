import { useContext, useState, } from 'react';
import { Button, ButtonGroup, Container, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Checkbox, FormControlLabel, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// Context
import { generateRelations, generateRelationsMap, IncludeRelationsToDatabase } from '../visualisation/generateRelations';
import DataContext from '../../context/dataContext';
import AuthContext from '../../context/authContext';
import { DeleteLink } from '../../graphqlCommunication/LinksInterface';
import { CollapsibleTable } from './CollapsibleTable';
import { Alert } from '@material-ui/lab';



const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.myDivider.main,
        color: theme.palette.text,
        fontSize: "1rem",
    },
    body: {
        fontSize: "1rem",
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(9),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        height: '100vh',
        maxWidth: "none",
    },
    paper: {
        padding: theme.spacing(2),
    },
    input: {
        minWidth: 160,
        maxWidth: 240,
    },
    inputWide: {
        minWidth: 160,
        maxWidth: 1200,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    text: {
        color: theme.palette.text.main,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.main,
    },
}));



export const LinkAnalysis = ({ linkTypeMap }) => {
    const classes = useStyles();

    // Context
    const contextType = useContext(AuthContext);
    const dataContext = useContext(DataContext);
    const termArray = dataContext.termArray;
    const linkArray = dataContext.linkArray;
    const setLinkArray = dataContext.setLinkArray;
    

    const [numbers, setNumbers] = useState({
        links: 0,
        terms: 0,
    });

    const [openAlert, setOpenAlert] = useState(false)


    const [genRelations, setGenRelations] = useState([]);
    const [genRelationsMap, setGenRelationsMap] = useState([]);

    // Add Links
    const handleAddLinks = () => {
        // Make a Array with all TermIDs  

        console.log(genRelationsMap)

        console.log(genRelations)

        let newRelations = []
        genRelationsMap.map(rel => {
            rel.links.map(obj => {
                if (obj.selection) {
                    newRelations.push(obj);
                }
            })
        })
        console.log("newRelations")
        console.log(newRelations)
        if (newRelations.length > 0) {
            IncludeRelationsToDatabase(newRelations, linkArray, setLinkArray, contextType.token)
            setOpenAlert(true)
        }
    }

    // Generate Links
    const handleAnalyseLinks = () => {
        // Make a Array with all TermIDs  
        let termIDmap = []
        //console.log(termArray)
        termArray.map((term, index) => {
            termIDmap[index] = term._id
        })

        // Generate Links  
        const relations = generateRelations(termArray);
        const relationsMap = generateRelationsMap(termArray);

        // Find New/ Not existig Relations
        const newRelationsMap = []
        let isNew = false;
        numbers.links = 0
        numbers.terms = 0
        relationsMap.map(rel => {

            let newLinks = []
            rel.links.map(obj => {
                isNew = true;
                linkArray.map(link => {
                    if (link.fromId == obj.source && link.toId == obj.target) {
                        isNew = false;
                    }
                    if (link.fromId == obj.target && link.toId == obj.source) {
                        isNew = false;
                    }
                })
                if (isNew) {
                    newLinks.push(obj)
                    numbers.links++
                }
            })

            if (newLinks.length > 0) {
                numbers.terms++
                rel.links = newLinks
                rel.numberOfLinks = newLinks.length
                newRelationsMap.push(rel)
            }
        })
        setNumbers(numbers)
        setGenRelationsMap(newRelationsMap)
        setGenRelations(relations)
    };

    // Snackbar
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false)
    }


    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Typography className={classes.text} variant="h6">Link Analysis</Typography>
                <Button variant="contained" color="primary" onClick={handleAnalyseLinks} >Start Analysis</Button>

                <Typography className={classes.text} variant="h6">There are {numbers.terms} terms with {numbers.links}  new links</Typography>

                

                <CollapsibleTable genRelationsMap={genRelationsMap} setGenRelationsMap={setGenRelationsMap} linkTypeMap={linkTypeMap}></CollapsibleTable>
                <Button className={classes.button} variant="contained" color="primary" onClick={handleAddLinks} >Create Links</Button>
            </Paper>
            <Snackbar
                open={openAlert}
                autoHideDuration={1000}
                onClose={handleClose}
                message="Note archived"
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}            >
                <Alert onClose={handleClose} severity="success">
                    Selected Links will created!
                </Alert>
            </Snackbar>
        </Container>
    )
};
