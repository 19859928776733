import React from 'react';
import {
    ReactFlowProvider
} from 'react-flow-renderer';
import { LayoutFlow } from './LayoutFlow';

// This component is only for Providing Data. https://reactflow.dev/docs/api/components/provider/
export const FlowChartProvider = ({linkTypeMap}) => {
    return (
        <> 
           <ReactFlowProvider>
                <LayoutFlow linkTypeMap={linkTypeMap} />
            </ReactFlowProvider>
        </>
    );
};
