import React from "react";

// Is an central storrage for data.
// Here it is an javascript object, but it can be also a number ore string.
export default React.createContext({
    termArray:null,
    termItem: null,
    setTermItem:(val) => {},
    setTermArray: ()=>{},

    linkArray:null,
    linkItem: null,
    setLinkItem:(val) => {},
    setLinkArray: ()=>{},

    viewArray:null,
    viewItem: null,
    setViewItem:(val) => {},
    setViewArray: ()=>{},

    graphTypeSelection: null,
    setGraphTypeSelection:(val) => {},

    setReloadContetnt: ()=>{},

});