//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS;


///////////////////
// Get all Items //
export const GetAllViews = (setViewArray, token, activeGroup) => {

    // Query
    const requestBody = {
        query: `
                query Views($activeGroup: [String!]) {
                    views (activeGroup: $activeGroup){
                        _id
                        name
                        description
                        date   
                        type
                        creator{
                            email
                        } 
                        domain                        
                        terms
                        links
                        settings                              
                    }
                }
            `,

            variables: {
                activeGroup: activeGroup
            }
    }

    // Communication with Backend
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const views = resData.data.views;
        setViewArray(views)
        //console.log(views)
    }).catch(err => {
        //console.log(err)
    });
}

/////////////////////
// Get Items by ID //
export const GetViewsByDomain = (setViewArray, token, domainMap, activeGroup) => {
    
    // Query
    const requestBody = {
        query: `
                query ViewsByDomain($domain: [String!],$activeGroup: [String!]) {
                    viewsByDomain(domain: $domain, activeGroup: $activeGroup) {
                        _id
                        name
                        description
                        date   
                        type
                        creator{
                            _id
                            email
                        }
                        domain                        
                        terms
                        links
                        settings                               
                    }
                }
            `,

        variables: {
            domain: domainMap,
            activeGroup: activeGroup
        }
    }

    // Communication with Backend
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const views = resData.data.viewsByDomain;
        setViewArray(views)
        //console.log(views)
    }).catch(err => {
        //console.log(err)
    });
}

///////////////////////
// Delete View by ID //
export const DeleteView = (viewId, setViewArray, viewArray, token) => {

    // Query
    const requestBody = {
        query: `
                mutation DeleteView ($id: ID!){
                    deleteView(viewId:$id){
                        name
                        _id
                    }
                }
            `,
        variables: {
            id: viewId
        }
    }


    // Functuion to Remove View from ViewArray
    const handelResData = (resData) => {
        //console.log("Delete View:" + viewId)
        const updateViews = viewArray.filter(view => {
            if (view._id === viewId) {
                //console.log("Remove View form List:" + view.name)
            }
            return view._id !== viewId;
        })
        setViewArray(updateViews)
    }

    // Communication with Backend    
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        handelResData(resData)
    }).catch(err => {
        //console.log(err)
    });
}


///////////////////////
// Update View by ID //
export const UpdateView = (viewItem, token) => {

    /*     if (viewItem.abbreviation == "" || !viewItem.abbreviation) { viewItem.abbreviation = "abbreviation" }
        if (!viewItem.source) {
            viewItem.source = ""
        } */

    //console.log("UpdateView")
    //console.log(viewItem)

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                    mutation UpdateView(
                        $viewId: ID!, 
                        $name: String!, 
                        $description: String!, 
                        $date: String!, 
                        $type: String!,
                        $domain: [String!],  
                        $terms: [String!], 
                        $links: [String!],  
                        $settings: String! 
                        
                    )
                    {
                        updateView(viewId: $viewId  viewInput:{
                                name: $name, 
                                description: $description, 
                                date: $date, 
                                type: $type, 
                                domain: $domain,
                                terms: $terms, 
                                links: $links, 
                                settings: $settings
                            }) 
                            {
                                _id
                                name
                                description
                                date   
                                type
                                domain                        
                                terms
                                links
                                settings 
                        }
                    }
                `,
        variables: {
            name: viewItem.name,
            date: viewItem.date,
            description: viewItem.description,
            viewId: viewItem._id,
            type: viewItem.type,
            domain: viewItem.domain,
            terms: viewItem.terms,
            links: viewItem.links,
            settings: viewItem.settings,
        }
    }


    // Functuion to update
    const handelResData = (resData) => {
        //setViewItem(viewItem)
    }

    // Communicate with Backend
    Communication(requestBody, handelResData, token);
}

/////////////////
// Create View // 
export const CreateView = (viewItem, setViewArray, setViewItem, UpdateTerm,termItem,token,activeGroup) => {

    if (viewItem.abbreviation === "" || !viewItem.abbreviation) { viewItem.abbreviation = "abbreviation" }

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                mutation CreateView( 
                    $name: String!, 
                    $description: String!, 
                    $date: String!, 
                    $type: String!,
                    $domain: [String!],  
                    $terms: [String!], 
                    $links: [String!],  
                    $settings: String!, 
                    $activeGroup: [String!] 
                    )
                    {
                    createView(viewInput: {
                        name: $name, 
                        description: $description, 
                        date: $date, 
                        type: $type, 
                        domain: $domain,
                        terms: $terms, 
                        links: $links, 
                        settings: $settings,
                        activeGroup: $activeGroup
                    }) 
                    {
                        _id
                        name
                        description
                        date   
                        type
                        creator{
                            _id
                            email
                        }
                        domain                        
                        terms
                        links
                        settings 
                    }
                }
            `,
        variables: {
            name: viewItem.name,
            date: viewItem.date,
            description: viewItem.description,
            type: viewItem.type,
            domain: viewItem.domain,
            terms: viewItem.terms,
            links: viewItem.links,
            settings: viewItem.settings,
            activeGroup: activeGroup
        }
    }

    // Functuion to Add new View to ViewArray
    const handelResData = (resData) => {
        if (resData.data.createView) {
            //console.log("Set new View to list");
            //console.log(resData.data.createView);
            setViewArray(prevState => {
                return [
                    ...prevState,
                    {
                        ...resData.data.createView
                    }
                ];
            });
            setViewItem(resData.data.createView)
            viewItem = resData.data.createView

            termItem.views[0] = viewItem._id
            UpdateTerm(termItem, token)

        }
        //return viewItem
    }


    // Communicate with Backend
    viewItem = Communication(requestBody, handelResData, token);
    return viewItem

}

///////////////////
// Communication //
export const Communication = (requestBody, handelResData, token) => {

    // Communication with Backend
    //console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        handelResData(resData);
    }).catch(err => {
        //console.log(err)
        return err;
    });


}
