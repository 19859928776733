/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useContext, } from 'react';
import { Fab, Grid, Box, Container, Paper, Divider, FormControlLabel, Checkbox, MenuItem, IconButton, Menu, Switch, Button, Select, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextRotateVerticalIcon from '@material-ui/icons/TextRotateVertical';
import TextRotationNoneIcon from '@material-ui/icons/TextRotationNone';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SaveIcon from '@material-ui/icons/Save';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ReactFlow, {
    //addEdge,
    //removeElements,
    //isNode,
    //useZoomPanHelper,
    Background,
    useNodesState,
    useEdgesState,
    useReactFlow,
} from 'react-flow-renderer';
import dagre from 'dagre';


// Import Components
import { CreateElementsForFlowChart } from "./CreateElementsForFlowChart";
import { FLowChartToolBar } from './FLowChartToolBar';
import './layouting.css';
import { FloatingEdge } from './FloatingEdge.jsx';
//import { theme } from '../../../theme';

// Use Context
import DataContext from '../../../context/dataContext';
import { SelectGraphType } from '../../basicComponents/SelectGraphType';
import { SettingDialog } from './SettingDialog';
import { CreateView, GetAllViews, UpdateView } from '../../../graphqlCommunication/ViewsInterface';
import authContext from '../../../context/authContext';
import { UpdateTerm } from '../../../graphqlCommunication/TermsInterface';
import { SaveViewDialog } from './SaveViewDialog';
import { TooltipStyle } from '../../basicComponents/HtmlTooltip.jsx';
import { SelectGraphLayoutType } from '../../basicComponents/SelectGraphLayoutType.jsx';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    container: {
        paddingTop: theme.spacing(9),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        height: "100vh",
        maxWidth: "none",
    },
    paper: {
        height: `calc(100% - ${theme.spacing(2)}px)`,
        padding: theme.spacing(1),
    },
    dividerTop: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
    },
    autocomplete: {
        width: 300,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        maxHeight: 60,
        [theme.breakpoints.down("sm")]: {
            width: 300,
        }
    },
    selection: {
        width: 300,
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        maxHeight: 60,
        minWidth: 80,
        maxWidth: 240,

    },
    fabOrientation: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        }
    },
    selectedCard: {
        minWidth: 200,
        border: '4px solid ' + theme.palette.primary.main,
        borderRadius: '5px',
        background: 'rgb(245, 245, 245)',
        filter: "drop - shadow(0px 10px 10px rgba(0, 0, 0, 1.0))",
    },
    card: {
        minWidth: 200,
        border: '1px solid ' + theme.palette.myDivider.main,
    },
    media: {
        height: 140,
    },
    cardContent: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        fontWeight: "bold",
    },
    chip: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontWeight: "bold",
    },
}));


const style = {
    background: 'none',
    width: '100%',
    height: '100%',
};

const onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView();
};


// Main 
export const LayoutFlow = ({linkTypeMap}) => {

    const { setCenter, zoomIn, zoomOut, fitView } = useReactFlow();

    // Constanses
    const classes = useStyles();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [viewSet, setViewSet] = useState({
        media: true,
        description: false,
        buttons: false,
        showView: true,
    });

    const [reset, setReset] = useState(true)
    const [reLoadGraph, setReLoadGraph] = useState(true)
    const [graphLayoutType, setGraphLayoutType] = useState("cycle")
    const [results, setResults] = useState([]);

    // Use Context
    const contextType = useContext(authContext);
    const token = contextType.token;
    const dataContext = useContext(DataContext);

    // Term 
    const setTermArray = dataContext.setTermArray;
    const termArray = dataContext.termArray;
    const termItem = dataContext.termItem;
    const linkArray = dataContext.linkArray;
    const linkItem = dataContext.linkItem
    const setTermItem = dataContext.setTermItem;

    // Graph
    const setGraphTypeSelection = dataContext.setGraphTypeSelection;
    const graphTypeSelection = dataContext.graphTypeSelection;
    // Views
    const setViewArray = dataContext.setViewArray;
    let viewArray = dataContext.viewArray;
    let viewItem = dataContext.viewItem;
    const setViewItem = dataContext.setViewItem;
    const [term4veiw, setTerm4veiw] = useState(termItem);


    // Edge Types
    const edgeTypes = {
        floating: (props) => <FloatingEdge {...props} linkTypeMap={linkTypeMap} linkItem={linkItem}  />,
    };

    // Make the horizontal an vertical orientation of the graph
    const getLayoutedElements = (nodes, edges, direction) => {

        // Hight and width of the nodes
        var nodeHeight = 300;
        if (direction === 'LR') nodeHeight = 50;
        if (viewSet.media) nodeHeight = nodeHeight + 140;
        if (viewSet.description) nodeHeight = nodeHeight + 200;
        if (viewSet.buttons) nodeHeight = nodeHeight + 100;

        var nodeWidth = 300;
        if (direction === 'LR') nodeHeight = 300;

        const dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));

        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });

        nodes.forEach((el) => {
            dagreGraph.setNode(el._id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((el) => {
            dagreGraph.setEdge(el.source, el.target);
        });

        dagre.layout(dagreGraph);

        const numOfNodes = nodes.length - 1
        let centerX = 0
        let centerY = 0
        let startAngle = 0
        let radiunFac = 1
        let countNewNodes = 0

        let radius = (nodeWidth + 25 * numOfNodes) * radiunFac

        nodes.map((el) => {
            if (el.position.x == 0 && el.position.y == 0) countNewNodes++
            if (el._id === termItem._id) {
                //console.log(el.name)

                centerX = el.position.x
                centerY = el.position.y + nodeHeight / 2

                //console.log(centerX)
                //console.log(centerY)
                if (centerX !== 0 && centerY !== 0) {

                    startAngle = Math.atan2(centerY, centerX) * 180 / Math.PI
                    radiunFac = 1.0
                    radius = Math.sqrt(Math.pow(centerX, 2) + Math.pow(centerY, 2)) * radiunFac + (nodeHeight + nodeWidth) / 2
                    //console.log("radius:" + radius)
                }
            }
        })


        /// Correct Start angle 
        startAngle = startAngle - (360 / numOfNodes * countNewNodes / 2)

        var count = 0
        //const LayoutVariant = "cycle" // cycle hierarchy
        nodes.map((el) => {

            //cycle
            if (graphLayoutType === "cycle") {
                // The Selected Node should be in the Center
                if (el.position.x == 0 && el.position.y == 0) {
                    if (el._id === termItem._id) {
                        el.position = {
                            x: 0 - nodeWidth / 2,
                            y: 0 - nodeHeight / 2,
                        }
                    } else {
                        count++
                        el.targetPosition = isHorizontal ? 'left' : 'top';
                        el.sourcePosition = isHorizontal ? 'right' : 'bottom';
                        const winkel = 360 * ((count - 1) / numOfNodes) + startAngle
                        //console.log("winkel:" + winkel)
                        const radians = winkel * (Math.PI / 180)
                        var x = (radius + 200) * Math.cos(radians)
                        var y = radius * Math.sin(radians)
                        el.position = {
                            x: x - nodeWidth / 2 + Math.random() / 1000,
                            y: y - nodeHeight / 2,
                        }
                    }
                }
                return el;
            }

            // hierarchy
            if (graphLayoutType === "hierarchy") {

                count++
                const nodeWithPosition = dagreGraph.node(el._id);
                el.targetPosition = isHorizontal ? 'left' : 'top';
                el.sourcePosition = isHorizontal ? 'right' : 'bottom';

                // unfortunately we need this little hack to pass a slightly different position
                // to notify react flow about the change. Moreover we are shifting the dagre node position
                // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
                el.position = {
                    x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                    y: nodeWithPosition.y - nodeHeight / 2,
                }

                return el;
            }
            return el;
        });

        return { nodes, edges };
    };

    // Load Pre settings
    useEffect(() => {
        loadElements();
    }, [termArray]);

    // Reload when termItem has Changed
    useEffect(() => {
        if (reLoadGraph) {
            loadElementsHandle();
        }
        console.log("// Reload when termItem has Changed")
        setReLoadGraph(true)
    }, [termItem, viewSet]);

    // Graph Layout (Direction)   
    const onLayout = useCallback(
        (direction) => {
            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nodes,
                edges,
                direction,
            );
            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        },
        [nodes, edges]
    );

    // Remove all elements
    const onRestore = useCallback(() => {
        ////console.log("onRestore")
        const restoreFlow = async () => {
            setEdges([])
            setNodes([])
        };
        restoreFlow();
    }, [setEdges, setNodes]);

    // Load Elements
    const loadElements = useCallback(() => {
        ////console.log("loadElements");
        //GetAllViews(setViewArray, token);
        const results = CreateElementsForFlowChart(termArray, termItem, setTermItem, linkArray, viewArray, classes, viewSet, setReLoadGraph, loadElementsHandle, setViewArray, contextType);


        console.log("---------------")  
        console.log(results)    
        // Make Layouting
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            results.nodes,
            results.edges,
            'TD'
        );
        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);
        setTerm4veiw(termItem)

    }, [nodes, edges]);


    // Save Current View an link it to Term
    const saveCurrentViewFunction = useCallback(() => {
        console.log("Make View for:");
        console.log(term4veiw.name);

        GetAllViews(setViewArray, contextType.token, contextType.activeGroup);
        viewItem.name = "View of: " + term4veiw.name
        viewItem.date = new Date().toISOString()
        viewItem.description = "Manual Created"
        viewItem.type = "termView"
        viewItem.domain = [""]
        viewItem.terms = []
        viewItem.links = []
        viewItem.settings = JSON.stringify(viewSet)

        // Nodes
        nodes.map((el) => {
            var termJson = {};
            termJson.position = el.position
            termJson.id = el.id

            // Convert to an JSON String
            viewItem.terms.push(JSON.stringify(termJson))
        })

        // Edges 
        edges.map((el) => {
            var linkJson = {};
            linkJson.id = el.id
            // Convert to an JSON String
            viewItem.links.push(JSON.stringify(linkJson))
        })

        // Check if current Item has still a view
        if (term4veiw.views !== "") {
            //console.log(termItem.views);
            if (term4veiw.views.length === 0) {
                // Create a new View
                console.log("Create an new View");
                CreateView(viewItem, setViewArray, setViewItem, UpdateTerm, term4veiw, contextType.token, contextType.activeGroup)
            } else {
                // Update View
                console.log("Update View");
                //console.log(viewItem.date);

                viewItem._id = term4veiw.views[0]
                UpdateView(viewItem, contextType.token)
                setViewItem(viewItem)

                // Update View Array
                viewArray = viewArray.map((view) => {
                    console.log(view._id === viewItem._id)
                    if (view._id === viewItem._id) {
                        return viewItem;
                    } else {
                        return view;
                    }
                })
                setViewArray(viewArray);
            }
        }
    }, [nodes, edges]);

    // Serarch in an Array ids and check if thay a there
    const arraySearch = (array, keyword) => {
        const searchTerm = keyword
        var isNoPart = true
        //console.log(searchTerm)
        //console.log(array)
        array.nodes.map(value => {
            if (value._id === searchTerm || value.id === searchTerm) {
                isNoPart = false
                return isNoPart
            }
            return value;
        })
        return isNoPart
    }

    const loadElementsHandle = () => {
        //GetAllViews(setViewArray, token);
        const newResults = CreateElementsForFlowChart(termArray, termItem, setTermItem, linkArray, viewArray, classes, viewSet, setReLoadGraph, loadElementsHandle, setViewArray, contextType);
        var resultsCon = []
        if (results.length !== 0 && !reset) {
            resultsCon = results;
            // Add new Objects and Links if thay are not there
            //console.log("---------------")
            //console.log(newResults)
            newResults.nodes.map(res => {
                if (res._id) {
                    if (arraySearch(results, res._id)) {
                        resultsCon.nodes.push(res)
                    }
                }
                if (res.id) {
                    if (arraySearch(results, res.id)) {
                        resultsCon.nodes.push(res)
                    }
                }
                return res;
            })
            newResults.edges.map(res => {
                if (res._id) {
                    if (arraySearch(results, res._id)) {
                        resultsCon.edges.push(res)
                    }
                }
                if (res.id) {
                    if (arraySearch(results, res.id)) {
                        resultsCon.edges.push(res)
                    }
                }
                return res;
            })
        } else {
            resultsCon = newResults;
        }
        setResults(resultsCon)

        // Layouting
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            resultsCon.nodes,
            resultsCon.edges,
            'TD'
        );

        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);

        setTerm4veiw(termItem)
    }

    // Select Graph Type in center view
    const handleChangeGraphType = (event, newValue) => {
        let { value } = event.target;
        setGraphTypeSelection(value)
    };

    const handleShowType = (event) => {
        setViewSet({ ...viewSet, [event.target.name]: event.target.checked });
    };

    // States and Handler for the Edit Dialog
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    // View selection (media, title, description,...)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const ITEM_HEIGHT = 48;

    // Handle for the Switch button
    const handleResetGraph = () => {
        setReset((prev) => !prev)
    }


    const [values, setValues] = useState(graphLayoutType);

    const handleChangeLayoutType = async (event, newValue) => {
        let value = event.target.value;
        //console.log(value)

        // Verwenden Sie await, um sicherzustellen, dass der Zustand aktualisiert wird, bevor der nächste Schritt ausgeführt wird
        await setValues(value);

        // Verwenden Sie den aktualisierten Zustand
        await setGraphLayoutType(value);

        fitView()
    };


    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <div style={{ width: "100%", height: "90%" }}>
                    <Grid direction="row" spacing={1} container>
                        <Grid item xs={4}>
                            <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                <SelectGraphType type={graphTypeSelection} onChange={handleChangeGraphType} classes={classes} />
                                {/* <Autocomplete
                                    className={classes.autocomplete}
                                    id="combo-box-demo"
                                    options={termArray}
                                    getOptionLabel={(termArray) => { return (termArray.name + " (id:" + termArray._id + ")") }}
                                    renderInput={(params) => <TextField {...params} label="Select Term" variant="outlined" />}
                                    onChange={(event, newValue) => { handleAutocmpleteSelection(event, newValue); }}
                                /> */}
                            </Box>
                        </Grid>


                        <Grid item xs={8}>
                            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                <div className={classes.root}>


                                    <SaveViewDialog termItem={termItem} setTerm4veiw={setTerm4veiw} saveCurrentViewFunction={saveCurrentViewFunction} classes={classes}
                                        render={(open) => (
                                            <TooltipStyle title="Save curren view" arrow>
                                                <Fab
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    className={classes.buttonTableHead}
                                                    onClick={open}
                                                    size="small" color="primary" aria-label="edit"
                                                >
                                                    <SaveIcon />
                                                </Fab>
                                            </TooltipStyle>
                                        )}
                                    ></SaveViewDialog>

                                    <Fab onClick={loadElements} size="small" color="primary" aria-label="edit">
                                        <AutorenewIcon />
                                    </Fab>

                                    <FLowChartToolBar nodeId={termItem._id} />
                                    {graphLayoutType === "hierarchy" &&
                                        <>
                                            <Fab className={classes.fabOrientation} onClick={() => onLayout('TB')} size="small" color="inherit" aria-label="edit">
                                                <TextRotateVerticalIcon />
                                            </Fab>
                                            <Fab className={classes.fabOrientation} onClick={() => onLayout('LR')} size="small" color="inherit" aria-label="edit">
                                                <TextRotationNoneIcon />
                                            </Fab>
                                        </>
                                    }
                                    <Fab className={classes.fabOrientation} onClick={() => onRestore()} size="small" color="inherit" aria-label="edit">
                                        <DeleteIcon />
                                    </Fab>
                                    <FormControlLabel
                                        control={<Switch checked={reset} onChange={handleResetGraph} />}
                                        label="Reset"
                                        labelPlacement="top"
                                    />
                                    {/* More Button */}
                                    <IconButton
                                        className={classes.fabOrientation}
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        color="inherit"
                                        onClick={handleClick}
                                    >
                                        <MoreHorizIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >

                                        <MenuItem >
                                            <SelectGraphLayoutType type={values.name} onChange={handleChangeLayoutType} classes={classes} />
                                        </MenuItem>
                                        <Divider></Divider>

                                        <MenuItem value={40}>
                                            <FormControlLabel className={classes.checkbox}
                                                control={<Checkbox name="showView" checked={viewSet.showView} onChange={handleShowType} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                                }
                                                label="Show View"
                                            />
                                        </MenuItem>

                                        <Divider></Divider>

                                        <MenuItem value={10}>
                                            <FormControlLabel className={classes.checkbox}
                                                control={<Checkbox name="media" checked={viewSet.media} onChange={handleShowType} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                                }
                                                label="Image"
                                            />
                                        </MenuItem>

                                        <MenuItem value={20}>
                                            <FormControlLabel className={classes.checkbox}
                                                control={<Checkbox name="description" checked={viewSet.description} onChange={handleShowType} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                                }
                                                label="Description"
                                            />
                                        </MenuItem>

                                        <MenuItem value={30}>
                                            <FormControlLabel className={classes.checkbox}
                                                control={<Checkbox name="buttons" checked={viewSet.buttons} onChange={handleShowType} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                                }
                                                label="Buttons"
                                            />
                                        </MenuItem>

                                        <Divider></Divider>




                                    </Menu>


                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider className={classes.dividerTop} />
                    {graphLayoutType === "hierarchy" &&
                        <>
                            <ReactFlow
                                style={style}
                                nodes={nodes}
                                edges={edges}
                                //edgeTypes={edgeTypes}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onLoad={onLoad}
                                snapToGrid={true}
                                fitView
                            >
                                <Background />
                            </ReactFlow>
                        </>
                    }

                    {graphLayoutType === "cycle" &&
                        <>
                            <ReactFlow
                                style={style}
                                nodes={nodes}
                                edges={edges}
                                edgeTypes={edgeTypes}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onLoad={onLoad}
                                snapToGrid={true}
                                fitView
                            >
                                <Background />
                            </ReactFlow>
                        </>
                    }
                </div>
            </Paper>
            <SettingDialog
                setGraphLayoutType={setGraphLayoutType}
                graphLayoutType={graphLayoutType}
                handleEditDialogClose={handleEditDialogClose}
                editDialogOpen={editDialogOpen}
            ></SettingDialog>
        </Container>
    );
};
