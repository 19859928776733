import React from 'react';
import clsx from 'clsx';
import { Select, InputLabel, MenuItem, FormControl, Tooltip, Typography } from '@material-ui/core';
import { HtmlTooltip } from './HtmlTooltip';


export const SelectTermType = ({ data, onChange, classes, termTypeMap }) => {


    const items = termTypeMap.map((val, index) => {
        const paraSplit = val.split(";")

        // Edible Columbs
        let p0 = paraSplit[0]
        let p1 = paraSplit[1]

        return (
            <MenuItem value={p0}>
                <HtmlTooltip title={p0} text={p1} />
            </MenuItem >
        )
    });

    const defaultType = termTypeMap.length > 0 ? termTypeMap[0] : '';

    console.log("data.type")
    console.log(data.type)

    return (
        <React.Fragment>
            <FormControl size="small" variant="outlined" className={clsx(classes.input, classes.margin)} >
                <InputLabel id="simple-select-label">Type</InputLabel>
                <Select
                    name="type"
                    labelId="simple-select-label"
                    id="simple-select"
                    value={data.type || defaultType}
                    onChange={event => onChange(event, data.type)}
                    label="type"
                >

                    <MenuItem aria-label="None" value="" />
                    {items}

                </Select>
            </FormControl>
        </React.Fragment>
    );
}