import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Box, Checkbox, Divider, FormControlLabel, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.text.disabled,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    menuItem: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(0),
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(0),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(0),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    flexButton: {
        flex: 1,
        minWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: theme.spacing(1),
    },
}));

export default function ViewTermDomainSpaceSB({ setDomainMap, domainMap, domainSpaceDefinition, setDomainSpaceDefinition }) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);


    //const [domainSpaceDefinition, setDomainSpaceDefinition] = React.useState(domainSpaceDefinition);


    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    let domainSpaceDefinitionArray = []
    Object.keys(domainSpaceDefinition).map((dom) => {
        domainSpaceDefinitionArray = domainSpaceDefinitionArray.concat(dom);
    })

    // Function to make the Buttoname
    const makeButtonName = (domainArray) => {
        // Set the Button Name
        let buttonName = ""
        domainArray.map((dn, index) => {
            // If all are selected
            if (domainArray[0] === domainSpaceDefinitionArray[0]) {
                buttonName = domainSpaceDefinitionArray[0]
            } else {
                if (buttonName.length <= 20) {
                    if (index == domainArray.length - 1) {
                        buttonName = buttonName + dn
                    } else {
                        buttonName = buttonName + dn + ", "
                    }
                } else {
                    buttonName = buttonName + "..."
                }
            }
        })
        if (buttonName === "") buttonName = "All"
        return (buttonName)
    }

    // Set Button Name
    let iniButtonName = ""
    domainSpaceDefinitionArray.map(dn => {
        iniButtonName = iniButtonName + " " + dn
    })
    const [buttonName, setButtonName] = React.useState(makeButtonName(domainMap));


    const handleClick = () => {
        /*  //console.log(domainSpaceDefinitionArray[selectedIndex])
         console.info(`You clicked ${domainSpaceDefinitionArray[selectedIndex]}`); */
    };

    // Handle On cklick on the Checkbox
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index == 0) {
            Object.keys(domainSpaceDefinition).map((option) => {
                domainSpaceDefinition[option] = event.target.checked
            })
        } else {
            domainSpaceDefinition[domainSpaceDefinitionArray[0]] = false
        }
        setDomainSpaceDefinition({ ...domainSpaceDefinition, [event.target.name]: event.target.checked });
        setOpen(true);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        // Create the Domainspace Map
        let selectedDomains = []
        Object.keys(domainSpaceDefinition).map((domain) => {
            if (domainSpaceDefinition[domain]) {
                selectedDomains = selectedDomains.concat(domain);
            }
        })
        // Set the Domain Space
        setDomainMap(selectedDomains)

        // Set the Button Name
        let buttonName = ""
        selectedDomains.map((dn, index) => {
            // If all are selected
            if (selectedDomains[0] === domainSpaceDefinitionArray[0]) {
                buttonName = domainSpaceDefinitionArray[0]
            } else {
                if (buttonName.length <= 20) {
                    if (index == selectedDomains.length - 1) {
                        buttonName = buttonName + dn
                    } else {
                        buttonName = buttonName + dn + ", "
                    }
                } else {
                    buttonName = buttonName + "..."
                }
            }
        })
        setButtonName(makeButtonName(selectedDomains))


        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    // Create Menu Items
    const menuItems = Object.keys(domainSpaceDefinition).map((option, index) => {
        //console.log(option, domainSpaceDefinition[option], index);
        return (
            <>
                <MenuItem
                    key={option + "_" + index}
                    //disabled={index === 99}
                    selected={index === selectedIndex}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={option}
                                color="primary"
                                checked={domainSpaceDefinition[option]}
                                onClick={(event) => handleMenuItemClick(event, index)}
                            />
                        }
                        label={option}
                    />
                </MenuItem>
                {index === 0 && (
                    <Divider className={classes.divider} />
                )}
            </>
        )

    });


    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="white" ref={anchorRef} aria-label="split button" className={classes.menuItem}>
                    <Button onClick={handleToggle} className={classes.flexButton}>{buttonName}</Button>
                    <Button
                        color="white"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        <Typography className={classes.text} variant="body1">Domain Space</Typography>
                                        <Divider className={classes.divider} />
                                        <>{menuItems}</>

                                        <Box textAlign='center'>
                                            <Button variant="contained" color="primary" onClick={handleClose} className={classes.button}>
                                                Apply
                                            </Button>
                                        </Box>

                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}