import { useContext, useState } from 'react';
import { Button, TextField, makeStyles, Paper, Typography, FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput } from '@material-ui/core';
import clsx from 'clsx';

// Logos
import { ReactComponent as Logo } from '../logo.svg';
import { ReactComponent as LogoDark } from '../logoDark.svg';
import MeshImage from '../mesh.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AuthContext from '../context/authContext';
import { Login, Signup } from '../graphqlCommunication/AuthInterface';
import { BorderColor } from '@material-ui/icons';
import { CreateGroup } from '../graphqlCommunication/GroupInterface';
import { initalSettings, loadInitalSettings } from '../settings/initialSettings';
import { CreateSetting } from '../graphqlCommunication/SettingsInterface';


// Style
const useStyles = makeStyles((theme) => ({
    login: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        background: "lightgray",
        height: "100vh",
        backgroundImage: `url(${MeshImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
    },
    input: {
        minWidth: 150,
        background: "white",
    },
    textField: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    typographyTitel: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),  // Linker Abstand
        marginRight: theme.spacing(2), // Rechter Abstand
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark
    },
    typographyBody: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),  // Linker Abstand
        marginRight: theme.spacing(2), // Rechter Abstand
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark
    },
    paper: {
        margin: theme.spacing(4),
        position: 200,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        alignItems: "non",
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
    },
    logo: {
        width: 200,
        height: 60,
        alignItems: "center",
    }
}));


export const WelcomePage = ({ userId, token, setSettingArray }) => {
    const classes = useStyles();
    const contextType = useContext(AuthContext);

    console.log("userId" + userId)
    // UseState Constances
    const [values, setValues] = useState({
        user: '',
        password: '',
        password_rep: '',
        showPassword: false,
        group: '',
        userId: userId,
    });


    const handleCreateGroupe = () => {

        // Create a new Group
        const permission = "admin" // The creator of a Group is always an admin
        const members = [userId+";"+permission]
        console.log("members")
        console.log(members)

        // Load initial Settings
        const Settings = initalSettings()

        // Create Settings and Grpup with all settings
        const createAllSettings = async () => {
            let settingIds = [];

            // Process settings one by one
            for (const settingItem of Settings) {
                const result = await CreateSetting(settingItem, setSettingArray, token);
                settingIds.push(result._id);
            }

            console.log("settingIds");
            console.log(settingIds);
            // Now we have all setting IDs, create the group
            CreateGroup(values.group, settingIds, members, contextType);
        };

        // Execute the function
        createAllSettings().then(() => {
            console.log("All settings created and group created");
        });
    }


    const handleChangePassWord = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };


    // Return
    return (
        <div className={classes.login}>
            <div className={classes.logo}>
                <Typography variant="h1">
                    <LogoDark className={classes.logo} />
                </Typography>
            </div>
            <>
                <Paper className={classes.paper}>
                    <Typography variant="h6" align="center" className={classes.typographyTitel} >
                        Welcome to LIMANTIC
                    </Typography>
                    <Typography variant="primary" align="center" className={classes.typographyBody} >
                        Before you can start, please create a new group.
                    </Typography>
                    <>
                        <TextField
                            name="group"
                            value={values.group}
                            onChange={handleChangePassWord('group')}
                            id="standard-basic"
                            label="Group Name"
                            className={clsx(classes.input, classes.textField)}
                            variant="outlined"
                            size="small"
                        />
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleCreateGroupe}>Create Groupe</Button>
                    </>
                </Paper>
            </>
        </div>
    );
}