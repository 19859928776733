import { useState, Fragment, useContext, useEffect, useRef } from 'react';
import AuthContext from '../../context/authContext';
import clsx from 'clsx';
//import clsx from 'clsx';

// Import Material 
import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


//Import Components
import { theme } from '../../theme';
import SnackBarSuccess from '../basicComponents/SnackBarSuccess';
import SnackBarError from '../basicComponents/SnackBarError';
import { CreateFeedback } from '../../graphqlCommunication/FeedbackInterface';
import { Rating } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 160,
        maxWidth: 220,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const FeedbackDialog = ({ handleFeedbackDialogClose, feedbackDialogOpen }) => {
    const classes = useStyles();
    const contextType = useContext(AuthContext);

    const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const [values, setValues] = useState({
        name: "",
        description: "",
        activeGroup: "",
        date: "",
        toNavigation: "",
        rating: 0,
    });

    const [textValue, setTextValue] = useState(values.description);

    const handleClose = () => {
        handleFeedbackDialogClose();
    };


    const handleSave = () => {

        let feedbackItem = {
            name: "",
            description: "",
            activeGroup: "",
            date: ""
        };

        setSuccess(false);
        setLoading(true);

        // Save data 
        if (values.name !== undefined) {
            feedbackItem.name = values.name;
        }
        if (values.description !== undefined) {
            feedbackItem.description = textValue;
        }
        if (!values.date || values.date === "") {
            feedbackItem.date = new Date().toISOString()
        }
        if (values.activeGroup !== undefined) {
            feedbackItem.activeGroup = "";
        }

        // Cobine all values to one string
        feedbackItem.description =  "{"+
                                    "\"rating\":"+   values.rating + "," // Value 
                                    +"\"general\": \""+ feedbackItem.description + "\"," //String
                                    +"\"toNavigation\": \""+ values.toNavigation +"\""  //String
                                    +"}";

        // Cummunication with Backend        
        CreateFeedback(feedbackItem, contextType.token)
            .then(result => {
                console.log('Feedback was send successfully:', result);
                handleClose();
                setOpenAlert(true)
                setSuccess(true);
                setLoading(false);

            })
            .catch(error => {
                console.error('Error send feedback:', error);
                setSuccess(true);
                setLoading(false);
                setOpenErrorAlert(true)
            });
    };

    const handleChange = (event, newValue) => {
        let { value, name } = event.target;
        if (name === "check") {
            const { checked } = event.target;
            value = checked;
        }
        ///////////// Hier weiter das muss wieder durch Quill ersetzt werden
        if (name === "description") {
            setTextValue(value)
        }
        /////////////////
        console.log("name: " + name + " value: " + value)
        setValues(values => {
            return {
                ...values,
                [name]: value
            };
        });
    };


    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false)
    }

    const handleCloseErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false)
    }


    return (
        <Fragment>
            <Dialog open={feedbackDialogOpen} onClose={handleFeedbackDialogClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth="true">
                <DialogTitle id="form-dialog-title">Send Feedback</DialogTitle>
                <DialogContent>

                    <Typography component="legend">My current satisfaction</Typography>
                    <Rating
                        id="rating"
                        label="Topic"
                        name="rating"
                        value={values.rating}
                        onChange={event => handleChange(event, values.rating)}
                    />

                    <TextField
                        className={classes.margin}
                        id="name"
                        label="Topic"
                        fullWidth
                        defaultValue={values.name}
                        name="name"
                        variant="outlined"
                        value={values.name}
                        onChange={event => handleChange(event, values.name)}
                    />

                    <TextField
                        margin="dense"
                        id="description"
                        label="General Feedback"
                        fullWidth
                        defaultValue={values.description}
                        multiline
                        rows={5}
                        name="description"
                        variant="outlined"
                        value={values.description}
                        onChange={event => handleChange(event, values.description)}
                    />

                    <TextField
                        margin="dense"
                        id="toNavigation"
                        label="To Navigation"
                        fullWidth
                        defaultValue={values.toNavigation}
                        multiline
                        rows={5}
                        name="toNavigation"
                        variant="outlined"
                        value={values.toNavigation}
                        onChange={event => handleChange(event, values.toNavigation)}
                    />

                    {values.creator &&
                        <DialogContentText>
                            {"Creator: " + values.creator.email}
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            disabled={loading}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>

            <SnackBarSuccess open={openAlert} handleClose={handleCloseAlert} message="Feedback was send successfully!" />
            <SnackBarError open={openErrorAlert} handleClose={handleCloseErrorAlert} message="Feedback has not bin sended!" />

        </Fragment>
    );
}