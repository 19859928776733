// General Imports
import { makeStyles, Grid, Drawer, Container, Fab, AppBar, Toolbar, Button, Paper, Divider } from '@material-ui/core'
import { Fragment, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Context
import AuthContext from './context/authContext';
import DataContext from './context/dataContext';

// Import Components
import { DetailView } from './components/mainView/DetailView';
import { Leftbar } from './components/navigation/Leftbar';
import { LoginPage } from './components/LoginPage';
import { Navbar } from './components/navigation/Navbar';
import { TermTable } from './components/mainView/TermTable';
import { RegisterTerm } from './components/addNewView/RegisterTerm';
import { ExcelReader } from './components/import/ExcelReader';
import { addHtmlToTermItem } from './components/mainView/ServicesMainView';
import { FlowChartProvider } from './components/visualisation/flowChart/FlowChartProvider';
import { ForceGraphContainer } from './components/visualisation/forceGraph/ForceGraphContainer';
import { CreateTerm, GetTermsByDomain } from './graphqlCommunication/TermsInterface';
import { countRelations } from './components/visualisation/generateRelations';
import { Administration } from './components/administration/Administration';
import { GetAllLinks } from './graphqlCommunication/LinksInterface';
import { GetSettingByIds } from './graphqlCommunication/SettingsInterface';
import { GetAllViews } from './graphqlCommunication/ViewsInterface';
import { LinkAnalysis } from './components/linkAnalysis/LinkAnalysis';
import { GetGroupById, getGroups } from './graphqlCommunication/GroupInterface';
import { WelcomePage } from './components/WelcomePage';

// React Reflex (Für die Manuelle verändern von Fenstergrößen)
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'
import 'react-reflex/styles.css'


// Styles
const useStyles = makeStyles((theme) => ({
  leftbar: {
    border: "1px solid #999",
    [theme.breakpoints.down("sm")]: {// Mobile
      display: "none",
    }
  },
  detailView: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      background: "lightgray",
      overflow: 'auto',
    }
  },
  flowChartlView: {
    display: "none",
    [theme.breakpoints.up("xl")]: {
      display: "block",
    }
  },
  drawer: {
    [theme.breakpoints.up("md")]: {// Mobile
      display: "none"
    },
  },
  container: {
    background: "lightgray",
    width: "100vw",
    right: 0,
  },
  fab: {
    background: "none",
    boxShadow: "none",
    color: theme.palette.common.white,
  },
  splitter: {
    backgroundColor: theme.palette.common.white,
    width: "15px"
  },
  divider: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: 0,
  },
}));

// Main Function
export const App = () => {
  const classes = useStyles();

  ///////////
  // Login //
  ///////////

  // All constances wich are set when the user is logged in
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [permission, setPermission] = useState("");
  const [groupMember, setGroupMember] = useState("");
  const [activeGroup, setActiveGroup] = useState("");
  const [group, setGroup] = useState("");
  const [settingArray, setSettingArray] = useState([]);
  const [viewArray, setViewArray] = useState([]);
  const [viewItem, setViewItem] = useState({ id: "", name: "", description: "", type: "", domain: "", terms: "", links: "", settings: "" });



  // Login an load all Related Data
  const login = (token, userId, tokenExpiration, permission, groupMember, activeGroup, group) => {
    if (permission !== "inactive") {
      console.log("------- Login ---------")

      // Parameter from the Login Method
      // User Parameter 
      setToken(token)
      setUserId(userId)
      setPermission(permission)

      // Group Parameter
      setGroupMember(groupMember)
      setActiveGroup(activeGroup)

      // Logging  
      if (groupMember.length != 0) {
        console.log("[info] Group Member")
        console.log(groupMember)

        // Get active group and load thier settings
        const getActiveGroup = async () => {
          console.log("[info] ActiveGroup")
          console.log(activeGroup)

          // Check type before accessing
          let ag = ""
          if (Array.isArray(activeGroup)) {
            ag = activeGroup[0]
          } else {
            ag = activeGroup
          }

          // Get active Group 
          const group = await GetGroupById(ag, setGroup);
          console.log("[info] Load Group")
          console.log(group)

          // Get Settings
          const settings = await GetSettingByIds(group.settings, setSettingArray, token);
          console.log("[info] Load Group specific Settings")
          console.log(settings)

          // Get Views
          const views = await GetAllViews(setViewArray, token, activeGroup);
          console.log("[info] Load Group specific Views")
          console.log(views)

          // Get User Permission
          console.log("[info] Permission of User")
          group.members.forEach(async (members) => {
            const parts = members.split(';') // members = "id;permission"
            const currentId = parts[0]
            const permission = parts[1]
            if (currentId === userId) {
              console.log(permission)
              setPermission(permission)
            }
          });
        };

        // Execute the function
        getActiveGroup().then(() => {
          console.log("[info] Settings and active group was loaded");
        });

      } else {
        console.log("[info] No Group Member!")
      }

    }
  }
  // Logout
  const logout = () => {
    setToken(null)
    setUserId(null)
  }


  ////////////////////
  // Handle Domains //
  ////////////////////

  // Domain Space Definition (Here the Domains will be defined for all Places and loadded from Settings)
  const [domainSpaceDefinition, setDomainSpaceDefinition] = useState({});
  useEffect(() => {
    const dsd = {}
    const settingName = "domain"
    console.log("settingArray")
    console.log(settingArray)
    settingArray.map((setting, setIndex) => {
      if (setting.name === settingName) {
        const li = setting.parameters.map((para, index) => {
          if (para.startsWith("{") && para.endsWith("}")) {
            para = para.substring(1, para.length - 1)
            const paraSplit = para.split(";")

            // Edible Columbs
            let p0 = paraSplit[1]
            let p1 = paraSplit[2]

            dsd[p0] = true;
          }
        })
      }
    })
    console.log("dsd")
    console.log(dsd)
    setDomainSpaceDefinition(dsd)
  }, [settingArray]);  // Runs when dependencyA or dependencyB changes

  // Create a Map with all Domains
  const [domainMap, setDomainMap] = useState([]);
  useEffect(() => {
    let domainSpaceDefinitionArray = []
    Object.keys(domainSpaceDefinition).map((dom) => {
      domainSpaceDefinitionArray = domainSpaceDefinitionArray.concat(dom);
    })
    setDomainMap(domainSpaceDefinitionArray);
  }, [domainSpaceDefinition]);


  ///////////////////////
  // Handle Term Types //
  ///////////////////////

  // Term Type Definition
  const [termTypeMap, setTermTypeMap] = useState([]);
  //console.log("termTypeMap")
  useEffect(() => {
    let dsd = []
    const settingName = "termType"
    settingArray.map((setting, setIndex) => {
      if (setting.name === settingName) {
        const li = setting.parameters.map((para, index) => {
          if (para.startsWith("{") && para.endsWith("}")) {
            para = para.substring(1, para.length - 1)
            const paraSplit = para.split(";")

            // Edible Columbs
            let p0 = paraSplit[1] // Name
            let p1 = paraSplit[2] // Description
            dsd = dsd.concat(p0+";"+p1);

          }
        })
        //console.log(dsd)
        setTermTypeMap(dsd)
      }
    })
  }, [settingArray]);

  ///////////////////////
  // Handle Link Types //
  ///////////////////////

  // Term Type Definition
  const [linkTypeMap, setLinkTypeMap] = useState([]);
  useEffect(() => {
    let dsd = []
    const settingName = "linkType"
    settingArray.map((setting, setIndex) => {
      if (setting.name === settingName) {
        const li = setting.parameters.map((para, index) => {
          if (para.startsWith("{") && para.endsWith("}")) {
            para = para.substring(1, para.length - 1)
            const paraSplit = para.split(";")

            // Edible Columbs
            let p0 = paraSplit[1] // Name
            let p1 = paraSplit[2] // Description
            dsd = dsd.concat(p0+";"+p1);
          }
        })
        setLinkTypeMap(dsd)
      }
    })
  }, [settingArray]);

  //////////////////////
  // Handle Term Data //
  //////////////////////

  // UseState Constances for Object Data
  const [termArray, setTermArray] = useState([]);
  const [termItem, setTermItemState] = useState({ id: "", name: "", description: "", descriptionHtml: "", abbreviation: "", type: "", source: "", domain: "", views: "" });

  // Add each time when termArray is set, descriptionHtml
  const setTermItem = (val) => {
    if (val.description !== undefined) {
      addHtmlToTermItem(setTermItemState, val, termArray)
    } else {
      setTermItemState(val)
    }
  }

  const [reloadContent, setReloadContetnt] = useState();

  // Get data via backend 
  useEffect(() => {
    GetTermsByDomain(setTermArray, token, domainMap, activeGroup);
  }, [token, domainMap, reloadContent]);

  // Count the number of relations 
  useEffect(() => {
    countRelations(termArray)
  }, [termArray]);

  // Import Terms from Excel
  const setDataFormImport = (data) => {
    data.map(termItem => {
      //console.log(termItem)
      if (!termItem.abbreviation || termItem.abbreviation === "") {
        termItem.abbreviation = ""
      }
      if (!termItem.type || termItem.type === "") {
        termItem.type = "General"
      }
      if (!termItem.images || termItem.images === "") {
        termItem.images = ""
      }

      if (!termItem.date || termItem.date === "") {
        termItem.date = new Date().toISOString()
      }
      if (!termItem.creator || termItem.creator === "") {
        termItem.creator = "exelImport"
      } else {
        //termItem.creator=termItem.creator
      }
      if (termItem.images) {
        //termItem.images = termItem.images
      }
      if (!termItem.domain || termItem.domain === "") {
        termItem.domain = "All"
      }
      if (!termItem.views || termItem.views === "") {
        /* termItem.view = [""] */
      }

      let creatIt = true
      termArray.map(term => {
        if (term.name === termItem.name && term.domain === termItem.domain) {
          //console.log("Term: " + termItem.name + " --> still exist")
          creatIt = false
        }
      })

      //console.log("termItem")
      //console.log(termItem)
      if (creatIt) {
        CreateTerm(termItem, setTermArray, token, activeGroup)
      }
      return termItem;
    })
  }


  //////////////////////
  // Handle Link Data //
  //////////////////////

  // UseState Constances for Object Data
  const [linkArray, setLinkArray] = useState([]);
  const [linkItem, setLinkItem] = useState({ id: "", fromId: "", toId: "", name: "", description: "", descriptionHtml: "", abbreviation: "", type: "" });

  // Get data via backend
  useEffect(() => {
    console.log("GetAllLinks")
    GetAllLinks(setLinkArray, token);
  }, [termArray]);


  /////////////////////
  // Handle Feedback //
  /////////////////////

  const [feedbackItem, setFeedbackItem] = useState({ id: "", name: "", description: "", descriptionHtml: "", activeGroup: ""});


  //GetAllLinks(setLinkArray);

  // Auto Generate Links if thay are not there
  /*  const relations = generateRelations(termArray);
   includeRelationsToDatabase(relations, linkArray, setLinkArray, token) */

  /////////////////
  // Other Stuff //
  /////////////////

  // Set Views (
  const [viewSet, setViewSet] = useState([2, 6, 3]);// Summe muss 11 ergeben (eine gridberite ist für das Sidebar)

  // Select of Graph view Type
  const [graphTypeSelection, setGraphTypeSelection] = useState("flowChart");// Summe muss 11 ergeben (eine gridberite ist für das Sidebar)

  // Toggle DetailView Drawer
  const [dawerState, setDawerState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDawerState(open);
  };


  ///////////
  // Retun //
  ///////////
  return (
    <Router>
      <AuthContext.Provider
        value={{
          token: token,
          userId: userId,
          permission: permission,
          groupMember: groupMember,
          activeGroup: activeGroup,
          group: group,
          login: login,
          logout: logout
        }}>

        {!token && (
          <div>
            <LoginPage error={''} />
          </div>
        )}

        {token && groupMember.length == 0 && (
          <div>
            <WelcomePage userId={userId} setSettingArray={setSettingArray} token={token} />
          </div>
        )}

        {token && groupMember.length != 0 && (
          <Fragment>
            <DataContext.Provider
              value={{
                termArray: termArray,
                termItem: termItem,
                setTermItem: setTermItem,
                setTermArray: setTermArray,

                linkArray: linkArray,
                linkItem: linkItem,
                setLinkItem: setLinkItem,
                setLinkArray: setLinkArray,

                viewArray: viewArray,
                viewItem: viewItem,
                setViewArray: setViewArray,
                setViewItem: setViewItem,

                graphTypeSelection: graphTypeSelection,
                setGraphTypeSelection: setGraphTypeSelection,

                setReloadContetnt: setReloadContetnt,
              }}>
              <div>
                <Navbar handleLogout={logout} setViewSet={setViewSet} setDomainMap={setDomainMap} domainMap={domainMap} domainSpaceDefinition={domainSpaceDefinition} setDomainSpaceDefinition={setDomainSpaceDefinition} />
                <Grid container >

                  <Grid item md={2} xl={1} className={classes.leftbar}>
                    <Leftbar />
                  </Grid>
                  {permission !== "read" && (
                    <>  </> //Leftbar?
                  )}

                  <Switch>

                    <Route exact path="/">
                      {viewSet[0] > 0 && (
                        <>

                          <Grid item xs={12} md={4} xl={viewSet[0]}>
                            <TermTable setDawerState={setDawerState} setReloadContetnt={setReloadContetnt} />
                          </Grid>


                          {viewSet[1] !== 0 && (
                            <>
                              <Grid item xl={viewSet[1]} className={classes.flowChartlView}>
                                {graphTypeSelection === "flowChart" && (
                                  <>
                                    {/* <FlowChartProviderTest setGraphTypeSelection={setGraphTypeSelection}></FlowChartProviderTest> */}
                                    <FlowChartProvider setGraphTypeSelection={setGraphTypeSelection} linkTypeMap={linkTypeMap}></FlowChartProvider>
                                  </>
                                )}
                                {graphTypeSelection === "forceGraph" && (
                                  <>
                                    <ForceGraphContainer termArray={termArray} termItem={termItem} setTermItem={setTermItem} setGraphTypeSelection={setGraphTypeSelection}></ForceGraphContainer>
                                  </>
                                )}
                              </Grid>
                            </>
                          )}

                          <Grid item md={6} xl={viewSet[2]} className={classes.detailView}>
                            <DetailView
                              termItem={termItem}
                              termArray={termArray}
                              setTermItem={setTermItem}
                              setTermArray={setTermArray}
                              domainSpaceDefinition={domainSpaceDefinition}
                              termTypeMap={termTypeMap}
                              linkTypeMap={linkTypeMap}
                            />
                          </Grid>

                        </>
                      )}

                      {viewSet[0] === 0 && (
                        <>
                          <Grid item xl={viewSet[1]} className={classes.flowChartlView}>
                            {graphTypeSelection === "flowChart" && (
                              <>
                                <FlowChartProvider setGraphTypeSelection={setGraphTypeSelection} linkTypeMap={linkTypeMap}></FlowChartProvider>
                              </>
                            )}
                            {graphTypeSelection === "forceGraph" && (
                              <>
                                <ForceGraphContainer termArray={termArray} termItem={termItem} setTermItem={setTermItem} setGraphTypeSelection={setGraphTypeSelection}></ForceGraphContainer>
                              </>
                            )}
                          </Grid>
                        </>
                      )}

                      {viewSet[0] === -1 && (
                        <>

                          <Grid item xs={12} md={10} xl={11}>
                            <ReflexContainer orientation="vertical" >

                              <ReflexElement >
                                <TermTable setDawerState={setDawerState} setReloadContetnt={setReloadContetnt} />
                              </ReflexElement>

                              <ReflexSplitter className={classes.splitter} >
                                <Divider className={classes.divider} />
                              </ReflexSplitter>

                              <ReflexElement >
                                {graphTypeSelection === "flowChart" && (
                                  <>
                                    <FlowChartProvider setGraphTypeSelection={setGraphTypeSelection} linkTypeMap={linkTypeMap}></FlowChartProvider>
                                  </>
                                )}
                                {graphTypeSelection === "forceGraph" && (
                                  <>
                                    <ForceGraphContainer termArray={termArray} termItem={termItem} setTermItem={setTermItem} setGraphTypeSelection={setGraphTypeSelection}></ForceGraphContainer>
                                  </>
                                )}
                              </ReflexElement>

                              <ReflexSplitter className={classes.splitter} />

                              <ReflexElement >
                                <DetailView
                                  termItem={termItem}
                                  termArray={termArray}
                                  setTermItem={setTermItem}
                                  setTermArray={setTermArray}
                                  domainSpaceDefinition={domainSpaceDefinition}
                                  termTypeMap={termTypeMap}
                                  linkTypeMap={linkTypeMap}
                                />
                              </ReflexElement>

                            </ReflexContainer>
                          </Grid>

                        </>
                      )}

                    </Route>

                    <Route exact path="/graph">
                      <Grid item xs={12} md={10} xl={11}>
                        <FlowChartProvider ></FlowChartProvider>
                      </Grid>
                    </Route>

                    <Route exact path="/forceGraph">
                      <Grid item xs={12} md={10} xl={11}>
                        <ForceGraphContainer termArray={termArray} termItem={termItem} setTermItem={setTermItem}></ForceGraphContainer>
                      </Grid>
                    </Route>

                    {permission !== "read" && (
                      <Route exact path="/edit">
                        <Grid item xs={12} md={10} xl={11}>
                          <RegisterTerm setTermArray={setTermArray} domainSpaceDefinition={domainSpaceDefinition} termTypeMap={termTypeMap} />
                        </Grid>
                      </Route>
                    )}

                    {permission !== "read" && (
                      <Route exact path="/import">
                        <Grid item xs={12} md={10} xl={11}>
                          <ExcelReader setDataFormImport={setDataFormImport} termArray={termArray} />
                        </Grid>
                      </Route>
                    )}

                    {permission !== "read" && (
                      <Route exact path="/linkAnalysis">
                        <Grid item xs={12} md={10} xl={11}>
                          <LinkAnalysis linkTypeMap={linkTypeMap}></LinkAnalysis>
                        </Grid>
                      </Route>
                    )}

                    {permission === "admin" && (
                      <Route exact path="/Administration">
                        <Grid item xs={12} md={10} xl={11}>
                          <Administration termArray={termArray} termItem={termItem} setTermItem={setTermItem} linkArray={linkArray} setLinkArray={setLinkArray} />
                        </Grid>
                      </Route>
                    )}


                  </Switch>
                </Grid>
              </div>


              <Fragment>
                <Drawer className={classes.drawer} anchor={'right'} open={dawerState} onClose={toggleDrawer(false)}>
                  <AppBar >
                    <Toolbar className={classes.toolbar}>
                      <Fab
                        className={classes.fab}
                        onClick={toggleDrawer(false)}
                        size="small">
                        <ArrowBackIcon />
                      </Fab>
                    </Toolbar>
                  </AppBar>
                  <Container className={classes.container}>
                    <DetailView
                      termItem={termItem}
                      termArray={termArray}
                      setTermItem={setTermItem}
                      setTermArray={setTermArray}
                      domainSpaceDefinition={domainSpaceDefinition}
                      termTypeMap={termTypeMap}
                      linkTypeMap={linkTypeMap}
                    />
                  </Container>
                </Drawer>
              </Fragment>

            </DataContext.Provider>
          </Fragment>
        )}

      </AuthContext.Provider>
    </Router >
  );
}

export default App;