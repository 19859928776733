//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS;
const dbName = "lima2"


// Connect backend to the right Database with dbName
export const connectToBackend = async () => {
    const BACKEND_ADRESS_CONNECT = BACKEND_ADRESS.replace(/\/graphql$/, "/connect-db");
    fetch(BACKEND_ADRESS_CONNECT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dbName })
    })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
}


// Login //
export const Login = (email, password, contextType) => {

    if (email.trim().length === 0 || password.trim().length === 0) {
        return;
    }


    // Queries Login 
    let requestBody = {
        query: `
            query Login($email: String!, $password: String!){
                 login(email: $email, password: $password) {
                    userId
                    token
                    tokenExpiration
                    permission
                    groupMember{_id}
                    activeGroup
                 }
             }
         `,
        variables: {
            email: email,
            password: password
        }
    }

    console.log(requestBody)

    // Communication to Backend
    Communication(requestBody, contextType)
}



// Singup for a new User //
export const Signup = async (name, email, password, permission, contextType, groupMember, setValues) => {
    if (email.trim().length === 0 || password.trim().length === 0) {
        return;
    }

    // Make Date
    const d = new Date();
    const date = d.toISOString();

    // Query CreateUser
    let requestBody = {
        query: `
                mutation CreateUser(
                $name: String!, 
                $email: String!, 
                $password: String!, 
                $permission: String!, 
                $groupMember:[String],
                $date: String!, 
                ){
                    createUser(
                    userInput: {
                        name: $name, 
                        email: $email, 
                        password: $password, 
                        permission: $permission, 
                        groupMember: $groupMember,
                        date: $date 
                    }) {
                        _id
                        email
                    }
                }
            `,
        variables: {
            name: name,
            email: email,
            password: password,
            permission: permission,
            groupMember: groupMember,
            date: date,

        }
    }

    // Communication to Backend
    const response = await fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + contextType.token,
        }
    });

    // Check response
    const data = await response.json();
    console.log(data)
    if (data && data.data) {

        console.log(data)
        if (data?.errors) {
            console.log(data.errors[0].message)

            console.log("Fail - to create new user")
            return data.errors[0].message;
        } else {
            console.log("Success - to create new user")
            console.log("User ID" + data.data.createUser._id)
            return ""
        }
    }

    return null;
}


// Singup for a new User //
export const SignupMember = async (name, email, password, permission, contextType, groupMember, setSignUpSuccess, setErrMessage, setValues) => {
    if (email.trim().length === 0 || password.trim().length === 0) {
        return;
    }

    // Make Date
    const d = new Date();
    const date = d.toISOString();

    // Query CreateUser
    let requestBody = {
        query: `
                mutation CreateUser(
                $name: String!, 
                $email: String!, 
                $password: String!, 
                $permission: String!,
                $activeGroup:[String!], 
                $groupMember:[String],
                $date: String!,
                ){
                    createUser(userInput: {
                    name: $name, 
                    email: $email, 
                    password: $password, 
                    permission: $permission,
                    activeGroup: $activeGroup,
                    groupMember: $groupMember 
                    date: $date
                    }) {
                        _id
                        email
                    }
                    
                }
            `,
        variables: {
            name: name,
            email: email,
            password: password,
            permission: permission,
            activeGroup: groupMember,
            groupMember: groupMember,
            date: date,

        }
    }


    // Communication to Backend
    const response = await fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + contextType.token,
        }
    });

    const data = await response.json();
    console.log(data)
    if (data && data.data) {
        //setUser(data.data.userById);

        console.log(data)
        if (data?.errors) {
            console.log(data.errors[0].message)
            console.log("Fail - to create new user")
            //data.data.error = data.errors[0].message    
        } else {
            console.log("Success - to create new user")
            console.log("Add to ...")
            console.log("User ID" + data.data.createUser._id)
        }

        return data;
    }

    return null;

}


// Update existing User //
export const UpdateUser = (userId, name, email, password, permission, contextType) => {
    if (email.trim().length === 0 || password.trim().length === 0) {
        return;
    }

    // Query CreateUser
    let requestBody = {
        query: `
                mutation UpdateUser(
                    $email: String!, 
                    $name: String!, 
                    $password: String!, 
                    $permission: String!,
                    $userId: ID!
                    ) {
                    updateUser(
                    userId:$userId, 
                    userInput: {
                        name: $name, 
                        email: $email, 
                        password: $password, 
                        permission: $permission 
                    }) {
                        _id
                        email
                        permission
                    }
                }
            `,
        variables: {
            userId: userId,
            name: name,
            email: email,
            password: password,
            permission: permission
        }
    }

    // Communication to Backend
    Communication(requestBody, contextType)

}





// Communication with backend
const Communication = (requestBody, contextType) => {

    // Connect backend to the right Database with dbName
    connectToBackend();


    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + contextType.token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        console.log("resData")
        console.log(resData)
        if (resData.data.login.token) {
            console.log("Done")
            contextType.login(
                resData.data.login.token,
                resData.data.login.userId,
                resData.data.login.tokenExpiration,
                resData.data.login.permission,
                resData.data.login.groupMember,
                resData.data.login.activeGroup,
            )
        } else {
            console.log("Err")
        }
    }).catch(err => {
        //console.log(err)
    });
}




// Get all User //
export const getUser = (setUsers) => {

    // Queries Login 
    let requestBody = {
        query: `
            query {
                users{
                    _id
                    name
                    email
                    permission
                    password
                }
            }
         `
    }

    // Communication to Backend
    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        setUsers(resData.data.users)
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}


// Get User by id //
export const GetUserById = async (userId, setUser) => {

    // Query userById
    let requestBody = {
        query: `
                mutation userById($userId: ID!) {
                    userById(userId:$userId) {
                        _id
                        name
                        email
                        password
                        permission
                    }
                }
            `,
        variables: {
            userId: userId
        }
    }

    // Communication to Backend
    const response = await fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();

    if (data && data.data && data.data.userById) {
        setUser(data.data.userById);
        return data.data.userById;
    }

    return null;

}

// Delete a User! Warning!!! 
export const deleteUser = (userId) => {

    if (userId.trim().userId === 0) {
        return;
    }

    // Query CreateUser
    let requestBody = {
        query: `
                mutation DeleteUser($userId: ID!) {
                    deleteUser(userId: $userId) {
                        _id
                        email
                    }
                }
            `,
        variables: {
            userId: userId,
        }
    }

    // Communication to Backend
    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}
