import { useContext, useState } from 'react';
import { Button, TextField, makeStyles, Paper, Typography, FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput } from '@material-ui/core';
import clsx from 'clsx';

// Logos
import { ReactComponent as Logo } from '../logo.svg';
import { ReactComponent as LogoDark } from '../logoDark.svg';
import MeshImage from '../mesh.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AuthContext from '../context/authContext';
import { Login, Signup } from '../graphqlCommunication/AuthInterface';
import { BorderColor } from '@material-ui/icons';
import { CreateGroup } from '../graphqlCommunication/GroupInterface';

const REACT_APP_INITIAL_START_CODE = process.env.REACT_APP_INITIAL_START_CODE;



// Style
const useStyles = makeStyles((theme) => ({
    login: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        background: "lightgray",
        height: "100vh",
        backgroundImage: `url(${MeshImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
    },
    input: {
        minWidth: 150,
        maxWidth: 200,
        background: "white",
    },
    textField: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonText: {
        margin: theme.spacing(0),
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark
    },
    paper: {
        margin: theme.spacing(4),
        position: 200,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        alignItems: "non",
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
    },
    paperSuccsess: {
        margin: theme.spacing(8),
        position: 200,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        alignItems: "non",
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),

    },
    logo: {
        width: 200,
        height: 60,
        alignItems: "center",
    }
}));


export const LoginPage = ({ error }) => {
    
    const classes = useStyles();
    const contextType = useContext(AuthContext);


    // UseState Constances
    const [values, setValues] = useState({
        user: '',
        password: '',
        password_rep: '',
        showPassword: false,
        group: '',
        userId: '',
    });

    const resetValues = () => {
        setValues({
            user: '',
            password: '',
            password_rep: '',
            showPassword: false,
            group: '',
            userId: '',
        });
    }


    const [signUp, setSignUp] = useState(false)
    const [stardCode, setStardCode] = useState("")
    const [isStadCodeInvalid, setIsStadCodeInvalid] = useState(false)    
    const [signUpSuccess, setSignUpSuccess] = useState(false)
    const [isPassworInvalid, setIsPassworInvalid] = useState(false)
    const [isUserInvalid, setIsUserInvalid] = useState(false)

    const [errMessage, setErrMessage] = useState("")

    // Functions
    const handleLogin = () => {
        Login(values.user, values.password, contextType)
    }

    // SignUp an new User
    const handleConfirmSignUp = () => {
        // Add an new User
        //const email = "text@test.de"
        //const password = "test"


        // Check if the start code is correct
        if (stardCode === REACT_APP_INITIAL_START_CODE) {

            // Get active group and load thier settings
            const waitForSignup = async () => {

                const permission = "admin" // an group Founder is always an admin

                //const errMessage = "Test"
                const errMessage = await Signup(values.name, values.user, values.password, permission, contextType, setValues)

                console.log("errMessage")
                console.log(errMessage)
                if (errMessage === "") {
                    setSignUpSuccess(true)
                    //resetValues()
                } else {
                    setSignUpSuccess(false)
                    setErrMessage(errMessage)
                }
            };

            // Execute the function
            waitForSignup().then(() => {
                console.log("[info] Confirm New User");
            });

        } else {
            console.log("Stard Code is invalid")
            console.log(REACT_APP_INITIAL_START_CODE)
            setIsStadCodeInvalid(true)
        }
    }

    const handleGoToSignUp = () => {
        setSignUp(true)
    }

    const handlBack = () => {
        setSignUpSuccess(false)
        setSignUp(false)
    }

    // Validate User Mail adress
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const validateUser = values => {
        if (values.user != "" && values.user.match(isValidEmail)) {
            setIsUserInvalid(false)
            setErrMessage("")
        } else {
            setIsUserInvalid(true)
            setErrMessage("example@test.de.")
        }
        if (values.user === "") {
            setIsUserInvalid(false)
            setErrMessage("")
        }
    };

    // Validate Password
    const validatePassword = values => {
        if (values.password === values.password_rep) {
            setIsPassworInvalid(false)
        } else {
            setIsPassworInvalid(true)
        }
        if (values.password === "") setIsPassworInvalid(true)
        if (values.password_rep === "") setIsPassworInvalid(true)
    };

    // Handle Change Password
    const handleChangePassWord = (prop) => (event) => {

        // Create updated data object with current value
        const updatedData = { ...values, [prop]: event.target.value };

        // Set the state
        setValues(updatedData);

        // Validate with current values
        if (prop === "password") values.password = event.target.value
        if (prop === "password_rep") values.password_rep = event.target.value
        validatePassword(updatedData)
        validateUser(updatedData)
    };

    /// Handle Show Password
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Handle Stard Code
    const handleStardCode = (event) => {
        setStardCode(event.target.value)
    };


    // Return
    return (
        <div className={classes.login}>
            <div className={classes.logo}>
                <Typography variant="h1">
                    <LogoDark className={classes.logo} />
                </Typography>
            </div>

            {!signUpSuccess && (
                <>
                    <Paper className={classes.paper}>

                        {/* <LogoDark className={classes.logo} /> */}
                        {signUp && (
                            <TextField
                                name="name"
                                error={isUserInvalid}
                                value={values.name}
                                onChange={handleChangePassWord('name')}
                                id="standard-basic"
                                label="Name"
                                className={clsx(classes.input, classes.textField)}
                                variant="outlined"
                                size="small"                                
                            />
                        )}
                        <TextField
                            name="user"
                            error={isUserInvalid}
                            value={values.user}
                            onChange={handleChangePassWord('user')}
                            id="standard-basic"
                            label="Email"
                            className={clsx(classes.input, classes.textField)}
                            variant="outlined"
                            size="small"
                            helperText={errMessage}
                        />
                        <FormControl className={clsx(classes.textField)} variant="outlined" size="small">
                            <InputLabel htmlFor="outlined-adornment-password" error={isPassworInvalid}>Password</InputLabel>
                            <OutlinedInput
                                error={isPassworInvalid}
                                helperText="Incorrect entry."
                                className={clsx(classes.input)}
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChangePassWord('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                        {signUp && (
                            <>
                                <FormControl className={clsx(classes.textField)} variant="outlined" size="small">
                                    <InputLabel htmlFor="outlined-adornment-password" error={isPassworInvalid}>Confirm Password</InputLabel>
                                    <OutlinedInput
                                        error={isPassworInvalid}
                                        label="Confirm Password"
                                        className={clsx(classes.input)}
                                        id="outlined-adornment-password_rep"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password_rep}
                                        onChange={handleChangePassWord('password_rep')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={90}
                                    />
                                </FormControl>
                                <TextField
                                    name="stardCode"
                                    error={isStadCodeInvalid}
                                    value={stardCode}
                                    onChange={handleStardCode}
                                    id="standard-basic"
                                    label="Start Code"
                                    className={clsx(classes.input, classes.textField)}
                                    variant="outlined"
                                    size="small"                         
                                />
                            </>
                        )}
                        {!signUp && (
                            <>
                                <Button variant="contained" color="primary" className={classes.button} onClick={handleLogin}>Login</Button>
                                <Button variant="text" color="secondary" className={classes.buttonText} onClick={handleGoToSignUp}>Sign up</Button>

                            </>
                        )}
                        {signUp && !signUpSuccess && (
                            <>
                                <Button variant="contained" color="primary" className={classes.button} onClick={handleConfirmSignUp} disabled={isPassworInvalid || isUserInvalid}>Sign up</Button>
                                <Button variant="text" color="secondary" className={classes.buttonText} onClick={handlBack}>Back</Button>
                            </>
                        )}
                        {signUp && signUpSuccess && (
                            <>
                                <Typography variant="h5">Succsess!</Typography>
                                <Button variant="text" color="secondary" className={classes.buttonText} onClick={handlBack}>Back</Button>
                            </>
                        )}
                    </Paper>

                </>
            )}

            {signUpSuccess && (
                <>
                    <Paper className={classes.paperSuccsess}>
                        <Typography variant="h6" align="center" className={classes.typographyBody} >
                            Congratulations!
                        </Typography>
                        <Typography variant="primary" align="center" className={classes.typographyBody} >
                            Your account has been successfully created.
                        </Typography>
                        <Typography variant="primary" align="center" className={classes.typographyBody} >
                            Welcome to the community!
                        </Typography>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handlBack}>Back</Button>
                    </Paper>
                </>
            )}

        </div>
    );
}