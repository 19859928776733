import { createTheme } from '@material-ui/core'
import { red, pink, purple, deepPurple, blue, cyan, green, yellow, orange, brown, blueGrey } from '@material-ui/core/colors';

// Default Theme: https://v4.mui.com/customization/default-theme/
const number = 900;

export const theme = createTheme({
    palette: {

        primary: {
            light: '#5a7683',
            main: '#2f4a56',
            dark: '#04222d',
            contrastText: '#fff',
        },
        secondary: {
            light: '#91dbf7',
            main: '#5ea9c4',
            dark: '#277a93',
            contrastText: '#04222d',
        },
        text: {
            primary: '#555',
            secondary: '#666666',
            white: 'rgba(255, 255, 255, 1)',
        },
        myDivider:{
            main: 'rgba(0, 0, 0, 0.12)',
        },
        edges: {
            primary: '#2f4a56',
            secondary: '#999',            
        },

        // Individal colors
        typeColors: {
            red: red[number],
            pink: pink[number],
            purple: purple[number],
            deepPurple: deepPurple[number],
            blue: blue[number],
            cyan: cyan[number],
            green: green[number],
            yellow: yellow[number],
            orange: orange[number],
            brown: brown[number],
            blueGrey: blueGrey[number]
        }
    }
})

