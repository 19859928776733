import { CreateLink } from "../../graphqlCommunication/LinksInterface";
import { MarkerType } from 'react-flow-renderer';
import { theme } from "../../theme";




export const generateRelations = (termArray) => {
    let relations = [];
    const idMap = new Map();
    // //console.log("--- generateRelations ---")
    // Hier werden alle beschreibungen duchsucht on einer der Terme dort vokommt. Wenn dieser vorkomt wird eine Relation erstellt.
    termArray.map(obj => {
        termArray.map(obj2 => {
            const found = obj2.description.toLowerCase().match(obj.name.replaceAll("+", "").toLowerCase())
            if (found !== null && obj.name !== obj2.name) {
                //console.log(obj.name +" - "+obj2.name)
                idMap.set(obj._id, obj.name)
                const linkObj = { "source": obj._id, "target": obj2._id, "value": 1 , "sourceName": obj.name, "targetName": obj2.name,};
                relations.push(linkObj)
            }
            return relations;
        });
        return relations;
    });
    return (relations)
}


export const generateRelationsMap = (termArray) => {
    
    let relationsMap = [];
    const idMap = new Map();
    let relations = [];
    // //console.log("--- generateRelations ---")
    // Hier werden alle beschreibungen duchsucht on einer der Terme dort vokommt. Wenn dieser vorkomt wird eine Relation erstellt.
    termArray.map(obj => {
        relations = [];
        termArray.map(obj2 => {
            
            let relLevel = "low"
            const foundHight = obj2.description.match(obj.name)
            if (foundHight !== null)  relLevel="hight"

            const foundMedium = obj2.description.toLowerCase().match(obj.name+" ")
            if (foundMedium !== null)  relLevel="medium"
        
            const found = obj2.description.toLowerCase().match(obj.name.replaceAll("+", "").toLowerCase())
            if (found !== null && obj.name !== obj2.name) {
                //console.log(obj.name +" - "+obj2.name)
                idMap.set(obj._id, obj.name)
                const linkObj = { 
                    "source": obj._id, 
                    "target": obj2._id, 
                    "value": 1 , 
                    "sourceName": obj.name, 
                    "targetName": obj2.name,
                    "targetDescription": obj2.description,
                    "type": "verwendet in",
                    "description": "autoLink",
                    relevance:relLevel, 
                    selection:true};
                relations.push(linkObj)
            }
            return relations;
        });
        //console.log(relations.length)
        if (relations.length>0){

            const entree = {
                name: obj.name,
                description: obj.description,
                numberOfLinks: relations.length,
                selection:true,
                links: relations
            }
            //console.log(entree)
            relationsMap.push(entree)
        }
        return relations;
    });
    return (relationsMap)
}

export const generateRelationsByLinks = (linkArray) => {
    let relations = [];
    linkArray.map(obj => {
        //console.log(obj._id)
        if (obj.description === "autoLink") {
            const linkObj = {
                "linkId": obj._id, 
                "source": obj.fromId,
                "target": obj.toId,
                "value": 1,
                "type": obj.type,
                "label": obj.type + "___" + obj.description,
                "description": obj.description,
                "style": { stroke: theme.palette.edges.secondary, strokeWidth: 2 },
                "labelStyle": {
                    fontWeight: 200,
                    fontSize: 20,
                    fill: theme.palette.edges.secondary,
                    orient: 'auto-start-reverse',
                    fontFamily: "Arial",
                },
                "markerEnd": {
                    type: MarkerType.ArrowClosed,
                    width: '30',
                    height: '20',
                    color: theme.palette.edges.secondary,
                    orient: 'auto-start-reverse',
                },
            };
            //console.log("linkObj")
            //console.log(linkObj)
            relations.push(linkObj)
        } else {
            const linkObj = {
                "linkId": obj._id, 
                "source": obj.fromId,
                "target": obj.toId,
                "value": 1,
                "type": obj.type,
                "label": obj.type + "___" + obj.description,
                "description": obj.description,
                "style": { stroke: theme.palette.edges.primary, strokeWidth: 2 },
                "labelStyle": {
                    fontWeight: 200,
                    fontSize: 20,
                    fill: theme.palette.edges.primary,
                    orient: 'auto-start-reverse',
                    fontFamily: "Arial",
                },
                "markerEnd": {
                    type: MarkerType.ArrowClosed,
                    width: '30',
                    height: '20',
                    color: theme.palette.edges.primary,
                    orient: 'auto-start-reverse',
                },
            };
            relations.push(linkObj)
        }
        //console.log("#relations")
        //console.log(relations)
        return relations;
    });
    return (relations)
}


export const IncludeRelationsToDatabase = (relations, linkArray, setLinkArray, token) => {

    ////console.log(" --- Create new Links ---")

    const newLinkItemTemplate = {
        type: "verwendet in",
        toId: "",
        fromId: "",
        name: "xxx",
        abbreviation: "",
        date: "",
        description: "autoLink",
    };

    // Timestamp
    const d = new Date();
    let currDate = d.toISOString();

    // Add new Links
    relations.map(obj => {
        // Check if the link exists in the DB
        const existingLink = linkArray.find(link => (link.fromId === obj.source && link.toId === obj.target));
        if (!existingLink) {
            const newLinkItem = newLinkItemTemplate
            newLinkItem.fromId = obj.source
            newLinkItem.toId = obj.target
            newLinkItem.type = obj.type
            newLinkItem.description = obj.description
            newLinkItem.date = currDate
            ////console.log("Create new Link:")
            ////console.log(newLinkItem)
            CreateLink(newLinkItem, setLinkArray, token)
        }
        return obj;
    });
}

// Count the relations in both directions
export const countRelations = (termArray) => {
    let count = 0;
    // Object Name enthalten in der Beschreibung von Object2
    termArray.map(obj => {
        termArray.map(obj2 => {
            //console.log("#TO DEBUG Qick Fix")

            // Hier gibt es ein Prblem mit Regula Expresseins --> Die müssen alle entwert oder ersetzt werden!

            const found = obj2.description.toLowerCase().match(obj.name.replaceAll("+", "").toLowerCase())
            if (found !== null && obj.name !== obj2.name) {
                count = count + 1;
            }
            return obj2;
        });
        ////console.log(obj.name +" - Count:"+ count)        
        obj.relations = count;
        count = 0;
        return obj;
    });

    // Object verwendet in der Beschreibung den Namen von Object2
    termArray.map(obj => {
        termArray.map(obj2 => {
            const found = obj.description.toLowerCase().match(obj2.name.replaceAll("+", "").toLowerCase())
            if (found !== null && obj.name !== obj2.name) {
                count = count + 1;
            }
            return obj2;
        });
        //console.log(obj.name +" - Count:"+ count)        
        obj.relations = obj.relations + count;
        count = 0;
        return obj;
    });

    return (termArray)
}