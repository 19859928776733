import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";



export default function SnackBarError({ open, handleClose, message }) { 

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <Alert onClose={handleClose} severity="error">
                {message}
            </Alert>
        </Snackbar>
    );
}


