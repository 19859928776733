import { makeStyles, Avatar } from '@material-ui/core';
import { deepOrange, green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    square: (props) => ({
        color: 'white',
        backgroundColor: props.bgColor || deepOrange[500],
    })
}));


// Generate a letter based on the name
function stringAvatar(name) {
    if (name) {
        if (name.split(' ').length > 1) {
            return {
                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
        } else {
            return {
                children: `${name.split(' ')[0][0]}`,
            };
        }
    }
}

// Generate a color based on the name
function stringToColor(name) {
    let hash = 0;
    let i;

    console.log(name)
    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    console.log("color")
    console.log(color)
    return color;
}

export default function UserAvatar({ user }) {
    // Defoult Color
    let bgColor='#000000'

    // Generate a color based on the name
    if (user.name){
        bgColor = stringToColor(user.name)
    }    
    const classes = useStyles({ bgColor });

    // Return Avatar with the color and the name
    return (
        <div className={classes.root}>
            <Avatar variant="circular" className={classes.square} {...stringAvatar(user.name)}>
            </Avatar>
        </div>
    );
    //Avatar variant=... 'circle' 'circular' 'rounded' 'square' 	
}