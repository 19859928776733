import { CreateSetting } from "../graphqlCommunication/SettingsInterface";


// Initial Settings
export function initalSettings() {
    const d = new Date();
    let currDate = d.toISOString();
    const Settings = [
        {
            _id: "domain", date: currDate, name: "domain", description: "definition of all Domains", parameters: [
                "{id_d01;All;Dies steht für alle Bereiche}",
                "{id_d03;Automotive; Dies steht für alle Dinge mit Rädern und Motoren}",
                "{id_d04;Botanik; Dies steht für alle Dinge, die lebendig sind}",
                "{id_d05;Architektur; Dies steht für alle Dinge, die von Menschen gebaut werden und von Menschen leben}"
            ]
        },
        {
            _id: "termType", date: currDate, name: "termType", description: "definition of all Term types", parameters: [
                "{id_tt01;General;Dies steht für alle Terme die keinen anderen Typ zugeortnet werden können.}",
                "{id_tt02;Standard;Dies steht für alles was ein Standard ist z.B. DIN, ISO ...}",
                "{id_tt03;Technologie;Technologie im heutigen Sinne ist die Wissenschaft und Lehre von der Technik zur Planung und Herstellung von Industrieprodukten. }",
                "{id_tt04;Projekt;Ein Projekt ist ein zielgerichtetes, einmaliges Vorhaben, das aus einem Satz von abgestimmten, gesteuerten Tätigkeiten besteht und durchgeführt werden kann, um unter Berücksichtigung von Vorgaben wie etwa Zeit, Ressourcen (zum Beispiel Finanzierung bzw. Kosten, Produktions- und Arbeitsbedingungen, Personal und Betriebsmittel) und Qualität ein Ziel zu erreichen.}",
                "{id_tt05;Objekt;Ist alles das was ein der realen Welt gibt.}",
                "{id_tt06;Methode;Eine Methode ist eine „systematische\" zielgerichtete Vorgehensweise, sowie planmäßiges Verfahren, welches für eine Vielzahl von Problemen zu einer sinnvollen Lösung führt.}",
                "{id_tt07;Theorie;Eine Theorie ist im allgemeinen Sprachgebrauch eine durch weitgehend spekulatives Denken gewonnene Erkenntnis oder ein System von Lehrsätzen, aus denen sich eine Erkenntnis ableiten lässt.}",
                "{id_tt08;Prozess;Ein Prozess kann als ein Verlauf, eine Entwicklung oder ganz allgemein als ein System von Bewegungen bezeichnet werden. Vergleichbare Begriffe sind auch „Hergang“, „Fortgang“, „Ablauf“ und „Vorgang“.}",
                "{id_tt09;Rolle;In einem Arbeitsablauf wird einem jedem der Beteiligten eine Rolle zugewiesen, die er erfüllen soll. }",
                "{id_tt10;Modell;Als Modell gilt in der Wissenschaft die Wiedergabe oder Abbildung eines bereits existierenden, vergangenen oder künftigen Originals. Modelle können konkret gegenständlich sein (etwa Puppenstuben), von grafischer und mathematischer Beschaffenheit (z. B. Geschäftsprozesse in einem Petri-Netz), rein abstrakt (Gleichungen wie E = mc²), oder die Form komplexer Theorien annehmen. }",
                "{id_tt11;Software;Software ist ein Sammelbegriff für Programme und die zugehörigen Daten.}",
                "{id_tt12;Ergebniss;Ein Ergebniss ist das, was bei einem Vorgang herauskommt.}",
                "{id_tt13;Hilfsmittel;Ein Hilfsmittel ist das was man zur erledigung von anderen dingen benötigt und kann z.B. eine Checkliste, Leitfaden, ... sein.  }",
                "{id_tt14;Unternehmen;Steht für alls was ein Unternhmen ist. Ein Unternehmen ist eine wirtschaftlich selbständige Organisationseinheit, die mit Hilfe von Planungs- und Entscheidungsinstrumenten Markt- und Kapitalrisiken eingeht und sich zur Verfolgung des Unternehmenszweckes und der Unternehmensziele eines oder mehrerer Betriebe bedient. }"
            ]
        },
        {
            _id: "linkType", date: currDate, name: "linkType", description: "definition of all link types", parameters: [
                "{id_lt00;relation;Beziehung für alles andere}",
                "{id_lt01;Oberbegriff von;Ist eine hirachische Beziehung. | Synonym: Eltern,  Spezifiziert |  Klassifizierung:  hirachisch}",
                "{id_lt02;Synonym von;Synonyme oder Synonyma sind sprachliche Ausdrücke oder Zeichen, die zueinander in der Beziehung der Synonymie stehen – einer der grundlegenden Typen von Bedeutungsbeziehungen bzw. Sinnrelationen. |  Klassifizierung: horizontal}",
                "{id_lt03;besteht aus; Wenn etwas aus etwas anderen besteht | Synonym:  | Klassifizierung:  hirachisch}",
                "{id_lt04;erzeugt;Wenn etwas, etwas anderes erzeugt. | Synonym: fertigt  | Klassifizierung:  horizontal, zeitlich}",
                "{id_lt05;verwendet;Wenn etwas, etwas anderes verwendet. | Synonym: benutzt   | Klassifizierung:  horizontal, intra (Term übergreifend)}",
                "{id_lt06;erfordert;Wenn etwas, etwas anderes erfordert. | Synonym: benötigt   | Klassifizierung:  hirachisch}",
                "{id_lt06;verfeinert;Wenn ein abstrakter Term durch einen spezifischer erkärung (Term) verfeinert wird und dardurch Zusatzinformationen geben kann | Synonym zu: wird deteiliert durch  | Klassifizierung: horizontal, intra (Term übergreifend)}",
                "{id_lt08;ist ein;Ist eine spezifischer ausprägung eines Begriffs. | Synonym:  | Klassifikation: hirachisch, intra (term übergreifend)}",
                "{id_lt09;entwickelt in;}"
            ]
        }
    ]
    return Settings
}

// Create Settings at database
export function loadInitalSettings(setSettingArray, token) {

    const Settings = initalSettings()

    // Create Settings at database
    Settings.forEach((settingItem) => {
        CreateSetting(settingItem, setSettingArray, token);
    });

    setSettingArray(Settings)
}

