import { useContext, useState, } from 'react';
import { Box, Button,  Container, Divider, Grid, IconButton, List, ListItem, ListItemText, Paper, TextField, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { NameInput } from './NameInput';
import { SelectTermType } from '../basicComponents/SelectTermType';
import { DescritionInput } from './DescriptionInput';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { CreateTerm } from '../../graphqlCommunication/TermsInterface';
import wiki from 'wikipedia';
import clsx from 'clsx';
import akronim from "akronim";

// Context
import AuthContext from '../../context/authContext';
import { SelectTermDomainSpace } from '../basicComponents/SelectTermDomainSpace';
import ImagePreviewDialog from '../basicComponents/ImagePreview';
import DataContext from '../../context/dataContext';
import { TooltipStyle } from '../basicComponents/HtmlTooltip';
import ErrorSnackBar from '../basicComponents/SnackBarError';
import SnackBarSuccess from '../basicComponents/SnackBarSuccess';
import SnackBarError from '../basicComponents/SnackBarError';


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(9),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
       
        maxWidth: "none",
        [theme.breakpoints.up("sm")]: {
            height: '100vh',
           /*  border: "1px solid #999" */
        },
    },
    gridContainer: {
        padding: theme.spacing(0),
        height: '98%',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        //height: `calc(100% - ${theme.spacing(4)}px)`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
     
    },
    input: {
        minWidth: 160,
        maxWidth: 240,
    },
    inputWide: {
        minWidth: 160,
        maxWidth: 1200,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    text: {
        color: theme.palette.text.main,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    infoText: {
        color: theme.palette.text.main,
        marginRight: theme.spacing(1),
    },
    topicText: {
        color: theme.palette.text.main,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(0),
        marginTop: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.main,
    },
    list: {
        height: `calc(90% - ${theme.spacing(8)}px)`,
        overflow: 'auto',
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },
    listItem: {
        //color: theme.palette.primary.light,
        /*  marginTop: theme.spacing(0),
         marginBottom: theme.spacing(0),
         paddingTop: theme.spacing(1),
         paddingBottom: theme.spacing(1), */
    },
    listTextItem: {
        //color: theme.palette.primary.light,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    dividerTop: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },
    dividerBottom: {
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },
}));




export const RegisterTerm = ({ setTermArray, domainSpaceDefinition, termTypeMap }) => {
    const classes = useStyles();


    // Context
    const contextType = useContext(AuthContext);
    const dataContext = useContext(DataContext);
    const termArray = dataContext.termArray;
    //const setTermArray = dataContext.setTermArray;

    // States and Handler for the Edit Dialog
    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [relatedTerms, setRelatedTerms] = useState({});
    const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [itemSelected, setItemSelected] = useState();
    const updateSelected = (index) => {
        setItemSelected(index)
    };


    const handleImagePreviewOpenClose = () => {
        setImagePreviewOpen(false);
    };

    const handlViewImage = () => {
        console.log(data)
        setImagePreviewOpen(true);
    }

    // Set Defalt Values
    const defaultType = termTypeMap.length > 0 ? termTypeMap[0].split(";")[0] : '';
    console.log("defaultType")
    console.log(defaultType)

    let domainSpaceDefinitionArray = []
    Object.keys(domainSpaceDefinition).map((dom) => {
        domainSpaceDefinitionArray = domainSpaceDefinitionArray.concat(dom);
    })
    const defaultDomain = domainSpaceDefinitionArray.length > 0 ? domainSpaceDefinitionArray[0] : '';

    console.log("domainSpaceDefinition")
    console.log(domainSpaceDefinition)

    const emptyForm = {
        name: '',
        description: '',
        descriptionHtml: '',
        abbreviation: '',
        type: defaultType,
        check: '',
        images: [],
        source: '',
        domain: [defaultDomain],
    }
    //Data ist the container for the data
    const [data, setData] = useState(emptyForm);
    console.log(data)

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (event, newValue) => {
        console.log(termTypeMap)
        let { value, name } = event.target;
        if (name === "check") {
            const { checked } = event.target;
            value = checked;
        }
        setData(data => {
            //console.log(data)
            //console.log(value)


            //console.log(name) 
            return {
                ...data,
                [name]: value
            };
        });
    };


    // Reset Form
    const handleResetForm = () => {
        //setData(emptyForm);
        setData(prevData => ({
            ...prevData,
            name: emptyForm.name,
            description: emptyForm.description,
            descriptionHtml: emptyForm.descriptionHtml,
            abbreviation: emptyForm.abbreviation,
            //type: emptyForm.type,
            check: emptyForm.check,
            images: emptyForm.images,
            source: emptyForm.source,
            //domain: emptyForm.domain,
        }));
        setFormSubmitted(false);
    }



    // Create the new Term
    const handleSubmit = () => {
        const { name, description, type, images, source, descriptionHtml, domain, abbreviation } = data;
        if (name !== "") {
            setFormSubmitted(false);
            const newTerm = [];
            newTerm.name = name;
            newTerm.description = description;
            newTerm.type = type;
            newTerm.images = images;
            newTerm.source = source;
            newTerm.descriptionHtml = descriptionHtml;
            newTerm.abbreviation = abbreviation;
            const d = new Date();
            let currDate = d.toISOString();
            newTerm.date = currDate;
            newTerm.domain = domain;

            // Backend Communiactaion

            CreateTerm(newTerm, setTermArray, contextType.token, contextType.activeGroup)
                .then(result => {
                    console.log('Term created successfully:', result);
                    setOpenAlert(true)
                    handleResetForm()
                })
                .catch(error => {
                    console.error('Error creating term:', error);
                    setOpenErrorAlert(true)
                });
        } else {
            //console.log("New Term has not been added: Missing Name");
        }
    }

    // Make Abbreviation of the Name
    const handlMakeAbbreviation = () => {
        const abb = akronim(data.name)
        setData(data => { return { ...data, "abbreviation": abb }; });
    }

    // Search Image in Google
    const handlSearchImage = () => {
        const searchQuery = data.name
        const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(searchQuery)}&tbm=isch`;
        // Open a new window with the Google Image Search URL
        window.open(searchUrl, '_blank');
    }

    // Open the Source Link
    const handlOpenLinke = () => {
        window.open(data.source, '_blank');
    }

    // Search in Wiki
    const handlSearchWiki = () => {
        const { name } = data;
        const dataObject = {};
        if (name !== "") {
            (async () => {
                try {
                    wiki.setLang("de")
                    const page = await wiki.page(name);
                    //console.log(page.fullurl);
                    const summary = await page.summary();
                    //console.log(summary);

                    const links = await page.links();
                    console.log(links);
                    for (let i = 0; i < links.length; i++) {
                        dataObject[links[i]] = {
                            description: "",
                            source: "",
                        };
                    }
                    console.log("dataObject");
                    console.log(dataObject);
                    setRelatedTerms(dataObject);

                    //setData(data => {return {...data,["description"]: summary.description};});
                    setData(data => { return { ...data, "description": summary.extract }; });
                    setData(data => { return { ...data, "descriptionHtml": summary.extract_html }; });
                    setData(data => { return { ...data, "source": page.fullurl }; });
                    if (summary.originalimage) {
                        setData(data => { return { ...data, "images": summary.originalimage.source }; });
                    }
                } catch (error) {
                    //console.log(error);
                    //=> Typeof wikiError
                }
            })()
        } else {
            //console.log("Name ist empty");
        }
    }

    // Search in Wiki for the related Terms
    const handlWikiSelection = (name) => {
        const dataObject = {};
        if (name !== "") {
            (async () => {
                try {
                    wiki.setLang("de")
                    const page = await wiki.page(name);
                    //console.log(page.fullurl);
                    const summary = await page.summary();
                    //console.log(summary);                   

                    //setData(data => {return {...data,["description"]: summary.description};});
                    setData(data => { return { ...data, "name": name }; });
                    setData(data => { return { ...data, "description": summary.extract }; });
                    setData(data => { return { ...data, "descriptionHtml": summary.extract_html }; });
                    setData(data => { return { ...data, "source": page.fullurl }; });
                    if (summary.originalimage) {
                        setData(data => { return { ...data, "images": summary.originalimage.source }; });
                    }
                } catch (error) {
                }
            })()
        }
    }



    // Create Listitems of all related Terms
    const relatedTermsList = relatedTerms && Object.keys(relatedTerms).map((name, index) => {

        // Parameter
        let description = ""
        let showIcon = false
        let radioButtonSelected = false
        const key = `listItems-${index}`;

        // Get the description of the related Term
        if (relatedTerms.hasOwnProperty(name)) {
            description = relatedTerms[name].description
        }


        // Click on item Function
        const itemClickFunction = () => {
            setItemSelected(index)
        }

        // Click on item Button Function
        const itemClickUseButton = () => {
            setItemSelected(index)
            radioButtonSelected = true
            handlWikiSelection(name)
        }

        // Ceck if the Term is already in the List
        termArray.map((term) => {
            if (term.name === name) {
                showIcon = true
            }
        })

        return (
            <ListItem className={classes.listItem} key={key} selected={itemSelected === index}>
                {showIcon && (
                    <TooltipStyle title={"This Term is in your List, but you can click to bring Term data to top"} arrow>
                        <IconButton
                            className={classes.fab}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            aria-label="radio button"
                            size="small"
                            onClick={itemClickUseButton}
                        >
                            <CheckCircleIcon />
                        </IconButton>
                    </TooltipStyle>
                )}
                {!showIcon && (
                    <TooltipStyle title={"Click here to bring Term data to top and ADD TO LIST"} arrow>
                        <IconButton
                            className={classes.fab}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            aria-label="radio button"
                            size="small"
                            onClick={itemClickUseButton}
                        >
                            {itemSelected === index && <RadioButtonCheckedIcon />}
                            {itemSelected != index && <RadioButtonUncheckedIcon />}

                        </IconButton>
                    </TooltipStyle>
                )}
                <ListItemText
                    className={classes.listTextItem}
                    primary={name}
                    //secondary={description != "" && description.substring(0, 200).concat(' ...')}
                    secondary={description != "" && description}
                />
            </ListItem>
        )

    });

    // Update the related Term
    const updateRelatedTerm = (key, newDescription, newSource) => {
        setRelatedTerms(prevTerms => ({
            ...prevTerms,
            [key]: {
                ...prevTerms[key],
                description: newDescription,
                source: newSource
            }
        }));
    };

    // Load Detalts of the related Terms
    const handleLoadDetails = async () => {

        wiki.setLang("de");

        const promises = Object.keys(relatedTerms).map(async (key) => {
            if (key !== "") {
                try {
                    const page = await wiki.page(key);
                    const summary = await page.summary();
                    const fullurl = await page.fullurl;
                    updateRelatedTerm(key, summary.extract, fullurl);
                } catch (error) {
                    console.error(`Error fetching details for ${key}:`, error);
                }
            }
        });

        await Promise.all(promises);
    };



    // Snackbar
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false)
    }

    const handleCloseErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false)
    }


    if (!formSubmitted) {
        return (
            <Container className={classes.container}>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12} sm={6} style={{ height: '100%' }}>
                        <Paper className={classes.paper}>
                            <Typography className={classes.text} variant="h6">Create a new Term</Typography>
                            <div>
                                <NameInput data={data} onChange={handleChange} classes={classes} name={"name"} />
                                <TooltipStyle title={"Search for the name in wikipedia and get informations"} arrow>
                                    <Button variant="contained" color="primary" onClick={handlSearchWiki} className={classes.button}>Search in Wiki</Button>
                                </TooltipStyle>
                                <TextField
                                    name="abbreviation"
                                    value={data.abbreviation}
                                    onChange={event => handleChange(event, data.abbreviation)}
                                    id="standard-basic"
                                    label="Abbreviation"
                                    className={clsx(classes.input, classes.margin)}
                                    variant="outlined"
                                    size="small"
                                />
                                <Button variant="contained" color="primary" onClick={handlMakeAbbreviation} className={classes.button}>Make Abb.</Button>

                                <SelectTermType data={data} onChange={handleChange} classes={classes} termTypeMap={termTypeMap} />

                                <SelectTermDomainSpace data={data} onChange={handleChange} classes={classes} domainSpaceDefinition={domainSpaceDefinition} />

                                {/*  <SelectTermDomainSpaceSB data={data} setData={setData} domainSpaceDefinition={domainSpaceDefinition}></SelectTermDomainSpaceSB> */}


                                <DescritionInput data={data} onChange={handleChange} classes={classes} />
                                <TextField
                                    id="images"
                                    label="Image URL"
                                    fullWidth
                                    defaultValue={data.images[0]}
                                    name="images"
                                    variant="outlined"
                                    size="small"
                                    value={data.images}
                                    className={clsx(classes.inputWide, classes.margin)}
                                    onChange={event => handleChange(event, data.images)}
                                />
                                <Button variant="outlined" color="primary" onClick={handlSearchImage} className={classes.button}>Search Image</Button>
                                <Button variant="outlined" color="primary" onClick={handlViewImage} className={classes.button}>View</Button>

                                <TextField
                                    id="source"
                                    label="Source URL"
                                    fullWidth
                                    defaultValue={data.source}
                                    name="source"
                                    variant="outlined"
                                    size="small"
                                    value={data.source}
                                    className={clsx(classes.inputWide, classes.margin)}
                                    onChange={event => handleChange(event, data.source)}
                                />
                                <Button variant="outlined" color="primary" onClick={handlOpenLinke} className={classes.button}>Open</Button>

                                {/* <CheckPublish data={data} onChange={handleChange} classes={classes} /> */}
                            </div>
                            <div>
                                <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.button}>Add to list</Button>
                            </div>

                        </Paper>

                    </Grid>
                    <Grid item xs={12} sm={6} style={{ height: '100%' }}>

                        <Paper className={classes.paper}>
                            <Typography className={classes.topicText} variant="h6">Explore related terms from Wiki</Typography>
                            <Typography className={classes.infoText} variant="caption" >There were {Object.keys(relatedTerms).length}  found </Typography>
                            <Button variant="outlined" color="primary" onClick={handleLoadDetails} className={classes.button}>Load details</Button>

                            <Divider className={classes.dividerTop} />
                            <Box className={classes.list}>
                                <List>{relatedTermsList}</List>
                            </Box>
                            <Divider className={classes.dividerBottom} />
                        </Paper>

                        <ImagePreviewDialog
                            imagePreviewOpen={imagePreviewOpen}
                            handleImagePreviewOpenClose={handleImagePreviewOpenClose}
                            imageUrl={data.images}
                        />

                        <SnackBarSuccess open={openAlert} handleClose={handleCloseAlert} message="New Term has bin added!" />
                        <SnackBarError open={openErrorAlert} handleClose={handleCloseErrorAlert} message="New Term has not bin added!" />

                    </Grid>
                </Grid>
            </Container >
        )
    } else {
        return (
            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography className={classes.text} variant="h6" >There is a new Term on the List!</Typography>
                    <Typography className={classes.text} variant="body1" >Name: {data.name}</Typography>
                    <Typography className={classes.text} variant="body1">Description: {data.description}</Typography>
                    <Typography className={classes.text} variant="body1">Type: {data.type}</Typography>
                    <Typography className={classes.text} variant="body1" >Image URL: {data.images[0]}</Typography>
                    <Typography className={classes.text} variant="body1">Domain Space: {data.domain}</Typography>
                    <Typography className={classes.text} variant="body1" >Source {data.source}</Typography>
                    <Typography className={classes.text} variant="body1">Public: {data.check}</Typography>
                    <Button variant="contained" color="primary" onClick={handleResetForm} className={classes.button}>Add new Term</Button>
                    <Button variant="outlined" color="primary" className={classes.button} >
                        <Link to="/" className={classes.link}>
                            Back to List
                        </Link>
                    </Button>
                </Paper>
            </Container>
        )
    }
}
