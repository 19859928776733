import { Container, makeStyles } from '@material-ui/core'
import { BarItems } from './BarItems';
import { useState } from 'react';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: "sticky",
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
    },
  }
}));


export const Leftbar = () => {
  const classes = useStyles();
  const [dawerState, setDawerState] = useState(false);

  // Return
  return (
      <Container className={classes.container}>
        <BarItems setDawerState={setDawerState} />
      </Container>
  );
}

