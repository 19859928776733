import { makeStyles, Container, Paper, Grid, Typography, Box, Fab, Menu, MenuItem, Divider, IconButton, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { useContext, useState } from 'react';
//import { Link } from 'react-router-dom';
import parse from 'html-react-parser'
//import axios from 'axios';

//import DataContext from '../../context/dataContext';
import AuthContext from '../../context/authContext';
import DataContext from '../../context/dataContext';

// Icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinkIcon from '@material-ui/icons/Link';
//import { AccountTree, ScatterPlot } from '@material-ui/icons';

// Import components
import { EditDialog } from './EditDialog'
import { ExternalLinkDialog } from './ExternalLinkDialog'
import { AccordionMod } from '../basicComponents/AccordionMod';
import { DeleteTerm } from '../../graphqlCommunication/TermsInterface';
import { TypeIcons } from './TypeIcons';
//import { FlowChartProvider } from '../visualisation/flowChart/FlowChartProvider';
import { DefinedLinks } from './links/Links';
import ImagePreviewDialog from '../basicComponents/ImagePreview';
import SnackBarSuccess from '../basicComponents/SnackBarSuccess';
import SnackBarError from '../basicComponents/SnackBarError';


// Style
const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(8),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        maxHeight: "100vh",
        maxWidth: "none",

        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    paper: {
        height: `calc(100% - ${theme.spacing(4)}px)`,
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    title: {
        paddingLeft: theme.spacing(1),
        color: theme.palette.primary.light,
    },
    fab: {
        color: theme.palette.primary.contrastText,
        background: theme.palette.secondary.main,
        marginLeft: theme.spacing(2),
    },
    linkButton: {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    avatar: {
        color: '#fff',
        backgroundColor: theme.palette.secondary.dark,
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    box: {
        paddingTop: theme.spacing(1),
    },
    divider: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        marginBottom: 0,
    },
    dividerMiddle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        minWidth: '200px',
    },
    images: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: "block",
        width: "100%",
        margin: "none",
        borderRadius: "1% 1% 1% 1%",
        maxHeight: "300px",
    },
    divAccordion: {
        height: `calc(50% - ${theme.spacing(8)}px)`,
        /* overflow: 'auto', */
        /*marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2), */


        // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        //height:"900px",// fixed the height
        //border: "2px solid black",
        /*       overflow: "hidden",
              overflowY: "scroll" // added scroll */

    },
    icons: {
        display: "flex",
        color: "white",
        alignItems: "center",
    },

    chips: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

// Main Function
export const DetailView = ({ termItem, setTermItem, termArray, setTermArray, domainSpaceDefinition, termTypeMap, linkTypeMap }) => {

    //console.log("linkTypeMap2")
    //console.log(linkTypeMap)
    //export const DetailView = ({  setTermItem }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)

    // Delete dialog
    const [openDelete, setOpenDelete] = useState(false);
    const handleClickOpenDelete = () => {
        setOpenDelete(true);
        setAnchorEl(false)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    // Use Context  
    const contextType = useContext(AuthContext);
    const dataContext = useContext(DataContext);
    //const linkArray = dataContext.linkArray;
    //const termItem = dataContext.termItem;
    //const setTermItem = dataContext.setTermItem;
    //const termArray = dataContext.termArray;
    //const setTermArray = dataContext.setTermArray;

    // States and Handler for the Edit Dialog
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };
    const handleEditDialogOpen = () => {
        handleClose()
        setEditDialogOpen(true);
    };

    // States and Handler for the externalLink Dialog
    const [externalLinkDialogOpen, setExternalLinkDialogOpen] = useState(false);
    const handleExternalLinkDialogClose = () => {
        setExternalLinkDialogOpen(false);
    };
    /* const handleExternalLinkDialogOpen = () => {
        handleClose()
        setExternalLinkDialogOpen(true);
    }; */


    // Menu Functions
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    // Open Link
    const handleClickLink = (event) => {
        const url = termItem.source
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    };

    // Delete Item Functions
    const handleDelete = () => {
        setAnchorEl(null);
        DeleteTerm(termItem._id, setTermArray, termArray, contextType.token)
        .then(result => {
            console.log('Term delete successfully:', result);           
            setOpenAlert(true)
        })
        .catch(error => {
            console.error('Error delete term:', error);
            setOpenErrorAlert(true)
        });
        setOpenDelete(false);
    };

    // Get Link sub compponent
    const LinkSubComponent = () => {
        return (
            <div>
                <DefinedLinks linkTypeMap={linkTypeMap} />
            </div>
        )
    }


    // States and Handler for the Edit Dialog
    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);

    const handleImagePreviewOpenClose = () => {
        setImagePreviewOpen(false);
    };

    const handlViewImage = () => {
        setImagePreviewOpen(true);
    }

    // Get Link sub compponent
    const ImageSubComponent = () => {
        return (
            <>
                {termItem.images && (
                    <div>
                        {termItem.images.map((item) => (
                            <img onClick={handlViewImage} src={item} alt={"" + item} className={classes.images} />
                        ))}
                        <ImagePreviewDialog
                            imagePreviewOpen={imagePreviewOpen}
                            handleImagePreviewOpenClose={handleImagePreviewOpenClose}
                            imageUrl={termItem.images[0]}
                        />
                    </div>
                )}
            </>
        )
    }


    // Get Link sub compponent
    const SynonymSubComponent = () => {
        return (
            <>
                <div className={classes.chips}>
                    <Chip
                        label="Synonym"
                        variant="outlined"
                        size="small" />
                    <Chip
                        label="Synonym"
                        variant="outlined"
                        size="small" />
                    <Chip
                        label="Synonym"
                        variant="outlined"
                        size="small" />
                </div>
            </>
        )
    }


    // Snackbar
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false)
    }

    const handleCloseErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false)
    }


    if (termItem !== undefined && termItem !== null) {


        let primaryName = termItem.name
        if (termItem.abbreviation !== "" && termItem.abbreviation !== "abbreviation") primaryName = primaryName + " (" + termItem.abbreviation + ")"

        return (
            <Container className={classes.container}>

                {/*  <Paper className={classes.paper}>
                    <AccordionMod title={"Image"} text={""} SubComponent={ImageSubComponent} defaultExp={true} />
                    <AccordionMod title={"Image"} text={""} SubComponent={ImageSubComponent} defaultExp={true} />
                </Paper>

                <Paper className={classes.paper}>
                    <AccordionMod title={"Image"} text={""} SubComponent={ImageSubComponent} defaultExp={true} />
                    <AccordionMod title={"Image"} text={""} SubComponent={ImageSubComponent} defaultExp={true} />
                </Paper> */}


                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} >
                            <Box display="flex" flexDirection="row" alignItems="center" className={classes.box}>
                                {termItem.type && (
                                    <TypeIcons type={termItem.type} />
                                )}
                                <Typography className={classes.title} variant="h6">{primaryName}</Typography>

                                {termItem.source && (
                                    <IconButton
                                        component="span"
                                        className={classes.linkButton}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleClickLink}
                                        aria-label="more"
                                        size="small" >
                                        <LinkIcon color="secondary" />
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            {contextType.permission !== "read" && (
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="flex-end">
                                    <div className={classes.icons}>
                                        <Fab
                                            className={classes.fab}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                            aria-label="more"
                                            size="small" >
                                            <MoreHorizIcon color="white" />
                                        </Fab>
                                    </div>

                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}>
                                        <MenuItem onClick={handleEditDialogOpen}>Edit</MenuItem>
                                        <MenuItem onClick={handleClickOpenDelete}>Delete</MenuItem>
                                        <MenuItem onClick={handleClose}>Analyse</MenuItem>
                                        {/* <MenuItem onClick={handleExternalLinkDialogOpen}>Add Picture</MenuItem> */}
                                    </Menu>
                                </Box>
                            )}

                        </Grid>
                        {/* <Grid item xs={12}>
                            {SynonymSubComponent()}
                        </Grid> */}


                        {/*  <Grid item xs={12}>
                            {termItem.images && (
                                <div>
                                    {termItem.images.map((item) => (
                                        <img src={item} className={classes.images} style={{ display: "block", width: "80%", margin: "none", borderRadius: "5% 5% 5% 0%" }} />
                                    ))}
                                </div>
                            )}
                        </Grid> */}
                        <Grid item xs={12}>


                            <Divider className={classes.divider} />
                            <Box className={classes.divAccordion}>
                                {/* <AccordionMod title={"Synonym"} text={""} SubComponent={SynonymSubComponent} defaultExp={true} /> */}
                                <AccordionMod title={"Image"} text={""} SubComponent={ImageSubComponent} defaultExp={true} />
                                <AccordionMod title={"Description"} text={parse(termItem.descriptionHtml)} SubComponent={() => <></>} defaultExp={true} />
                                <AccordionMod title={"Links"} text={""} SubComponent={LinkSubComponent} defaultExp={true} ></AccordionMod>
                                {termItem.creator &&
                                    <AccordionMod title={"More"} text={"Id:" + termItem._id + " | Creator:" + termItem.creator.email} SubComponent={() => <></>} defaultExp={false} />
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>


                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete Term"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you realy want delete the "{termItem.name}" ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={handleDelete} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>

                <EditDialog
                    termItem={termItem}
                    setTermItem={setTermItem}
                    handleEditDialogClose={handleEditDialogClose}
                    editDialogOpen={editDialogOpen}
                    domainSpaceDefinition={domainSpaceDefinition}
                    termTypeMap={termTypeMap}
                    setTermArray={setTermArray}
                    termArray={termArray}
                ></EditDialog>
                <ExternalLinkDialog
                    termItem={termItem}
                    setTermItem={setTermItem}
                    handleExternalLinkDialogClose={handleExternalLinkDialogClose}
                    externalLinkDialogOpen={externalLinkDialogOpen}
                    token={contextType.token}
                ></ExternalLinkDialog>

                <SnackBarSuccess open={openAlert} handleClose={handleCloseAlert} message="Term has bin deleted!" />
                <SnackBarError open={openErrorAlert} handleClose={handleCloseErrorAlert} message="Term has not bin deleted!" />


            </Container>
        );
    }
}
