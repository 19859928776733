import React from 'react';
import clsx from 'clsx';
import { Select, InputLabel, MenuItem, FormControl, Tooltip } from '@material-ui/core';
import { HtmlTooltip } from './HtmlTooltip';


export const SelectLinkType = ({ data, onChange, classes, linkTypeMap, nameIn }) => {


    const items = linkTypeMap.map((val, index) => {
        const paraSplit = val.split(";")

        // Edible Columbs
        let p0 = paraSplit[0]
        let p1 = paraSplit[1]

        return (
            <MenuItem value={p0}>
                <HtmlTooltip title={p0} text={p1} />
            </MenuItem>
        )
    });

    if (!nameIn) {
        nameIn = "type"
    }

    return (
        <React.Fragment>
            <FormControl size="small" variant="outlined" className={clsx(classes.input, classes.margin)} >
                <InputLabel id="simple-select-label">Type</InputLabel>
                <Select
                    name={nameIn}
                    labelId="simple-select-label"
                    id="simple-select"
                    value={data.type}
                    onChange={event => onChange(event, data.type)}
                    label="Type"

                >
                    <MenuItem aria-label="None" value="" />
                    {items}
                </Select>
            </FormControl>
        </React.Fragment>
    );
}

{/* <MenuItem value="besteht aus">besteht aus</MenuItem>
<MenuItem value="ist Teil von">ist Teil von</MenuItem>
<MenuItem value="beinhaltet">beinhaltet</MenuItem>
<MenuItem value="ist auch">ist auch</MenuItem>
<MenuItem value="used in">verwendet in</MenuItem> */}