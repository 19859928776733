//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

import ErrorSnackBar from "../components/basicComponents/SnackBarError";
import { connectToBackend } from "./AuthInterface";

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS;


///////////////////
// Get all Items //
export const GetAllTerms = (setTermArray, token) => {

    // Query
    const requestBody = {
        query: `
                query {
                    terms{
                        _id
                        name
                        description
                        date   
                        type
                        creator {
                            email
                        }
                        images                        
                        source
                        abbreviation
                        domain    
                        views                           
                    }
                }
            `
    }

    // Communication with Backend
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const terms = resData.data.terms;
        setTermArray(terms)
        //console.log(terms)
    }).catch(err => {
        //console.log(err)
    });
}

/////////////////////
// Get Items by ID //
export const GetTermsByDomain = (setTermArray, token,domainMap, activeGroup) => {


    // Query
    const requestBody = {
        query: `
                query TermsByDomain($domain: [String!], $activeGroup: [String!]) {
                    termsByDomain(domain: $domain, activeGroup: $activeGroup) {
                        _id
                        name
                        description
                        date   
                        type
                        creator {
                            email
                        }
                        images                        
                        source
                        abbreviation
                        domain
                        views                              
                    }
                }
            `,
    
            variables: {
                domain: domainMap,
                activeGroup: activeGroup
            }    
        }

    // Communication with Backend
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const terms = resData.data.termsByDomain;
        setTermArray(terms)
        //console.log(terms)
    }).catch(err => {
        //console.log(err)
    });
}

///////////////////////
// Delete Term by ID //
export const DeleteTerm = (termId, setTermArray, termArray, token) => {

    // Query
    const requestBody = {
        query: `
                mutation DeleteTerm ($id: ID!){
                    deleteTerm(termId:$id){
                        name
                        _id
                    }
                }
            `,
        variables: {
            id: termId
        }
    }


    // Functuion to Remove Term from TermArray
    const handelResData = (resData) => {
        //console.log("Delete Term:" + termId)
        const updateTerms = termArray.filter(term => {
            if (term._id === termId) {
                //console.log("Remove Term form List:" + term.name)
            }
            return term._id !== termId;
        })
        setTermArray(updateTerms)
    }

   // Communicate with Backend
   connectToBackend(); // DAS MUSS NOCH WEITER Getestet werden!!!!!!!!!!!!!!!!

   return new Promise((resolve, reject) => {

       // Communication with Backend
       //console.log(JSON.stringify(requestBody))
       fetch(BACKEND_ADRESS, {
           method: 'POST',
           body: JSON.stringify(requestBody),
           headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + token,
           }
       }).then(res => {
           if (res.status !== 200 && res.status !== 201) { 
               throw new Error('Failed')
           }
           return res.json();
       }).then(resData => {
           handelResData(resData);
           resolve(resData);
       }).catch(err => {
           //console.log(err)
           //return err;
           reject(err);
       });
   });


  /*   // Communication with Backend    
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        handelResData(resData)
    }).catch(err => {
        //console.log(err)
    }); */
}


///////////////////////
// Update Term by ID //
export const UpdateTerm = (termItem, token) => {

    if(termItem.abbreviation === "" || !termItem.abbreviation){termItem.abbreviation = "abbreviation"}
    if (!termItem.source) {
        termItem.source = ""
    }

    //console.log("UpdateTerm")
    //console.log(termItem)

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                    mutation UpdateTerm(
                        $termId: ID!, 
                        $name: String!, 
                        $description: String!, 
                        $abbreviation: String!, 
                        $date: String!, 
                        $type: String!, 
                        $images: [String!], 
                        $source: String!,
                        $domain: [String!], 
                        $views: [ID] 
                    )
                    {
                        updateTerm(termId: $termId  termInput:{
                                name: $name, 
                                description: $description, 
                                abbreviation: $abbreviation, 
                                date: $date, 
                                type: $type, 
                                images: $images, 
                                source: $source, 
                                domain: $domain,
                                views: $views
                            }) 
                            {
                                _id
                                name
                                abbreviation
                                description
                                date
                                type
                                creator{
                                    _id
                                }
                                images
                                source
                                abbreviation
                                domain
                                views
                        }
                    }
                `,
        variables: {
            name: termItem.name,
            abbreviation: termItem.abbreviation,
            date: termItem.date,
            description: termItem.description,
            termId: termItem._id,
            type: termItem.type,
            images: termItem.images,
            source: termItem.source,
            domain: termItem.domain,
            views: termItem.views,
        }
    }


    // Functuion to update
    const handelResData = (resData) => {
        //setTermItem(termItem)
    }

    // Communicate with Backend
    connectToBackend(); // DAS MUSS NOCH WEITER Getestet werden!!!!!!!!!!!!!!!!

    return new Promise((resolve, reject) => {

        // Communication with Backend
        //console.log(JSON.stringify(requestBody))
        fetch(BACKEND_ADRESS, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) { 
                throw new Error('Failed')
            }
            return res.json();
        }).then(resData => {
            handelResData(resData);
            resolve(resData);
        }).catch(err => {
            //console.log(err)
            //return err;
            reject(err);
        });
    });

}

/////////////////
// Create Term // 
export const CreateTerm = (termItem, setTermArray, token,activeGroup) => {

    console.log("CreateTerm")   
    console.log(typeof activeGroup)
    console.log(activeGroup)

    
    if(termItem.abbreviation === "" || !termItem.abbreviation){termItem.abbreviation = "abbreviation"}

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                mutation CreateTerm( 
                    $name: String!, 
                    $description: String!, 
                    $abbreviation: String!, 
                    $date: String!, 
                    $type: String!, 
                    $images: [String!], 
                    $source: String!,
                    $domain: [String!],
                    $views: [ID],
                    $activeGroup: [String!],
                    )
                    {
                    createTerm(termInput: {
                        name: $name, 
                        description: $description, 
                        abbreviation: $abbreviation, 
                        date: $date, 
                        type: $type, 
                        images:$images, 
                        source: $source,
                        domain: $domain,
                        views: $views,
                        activeGroup: $activeGroup,
                    }) 
                    {
                        _id
                        name
                        abbreviation
                        description
                        type
                        creator{
                            _id
                            email
                        }
                        images
                        source
                        domain
                        views
                    }
                }
            `,
        variables: {
            name: termItem.name,
            abbreviation: termItem.abbreviation,
            date: termItem.date,
            description: termItem.description,
            type: termItem.type,
            images: termItem.images,
            source: termItem.source,
            domain: termItem.domain,
            views: termItem.views,
            activeGroup: activeGroup,
        }
    }

    // Functuion to Add new Term to TermArray
    const handelResData = (resData) => {
        if (resData.data.createTerm) {
            //console.log("Set new Term to list");
            //console.log(resData.data.createTerm);
            setTermArray(prevState => {
                return [
                    ...prevState,
                    {
                        ...resData.data.createTerm
                    }
                ];
            });
        }
    }

    // Communicate with Backend
    connectToBackend(); // DAS MUSS NOCH WEITER Getestet werden!!!!!!!!!!!!!!!!

    return new Promise((resolve, reject) => {

        // Communication with Backend
        //console.log(JSON.stringify(requestBody))
        fetch(BACKEND_ADRESS, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) { 
                throw new Error('Failed')
            }
            return res.json();
        }).then(resData => {
            handelResData(resData);
            resolve(resData);
        }).catch(err => {
            //console.log(err)
            //return err;
            reject(err);
        });
    });

}

///////////////////
// Communication //
export const Communication = (requestBody, handelResData, token) => {

    connectToBackend(); // DAS MUSS NOCH WEITER Getestet werden!!!!!!!!!!!!!!!!

    return new Promise((resolve, reject) => {

        // Communication with Backend
        //console.log(JSON.stringify(requestBody))
        fetch(BACKEND_ADRESS, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) { 
                throw new Error('Failed')
            }
            return res.json();
        }).then(resData => {
            handelResData(resData);
            resolve(resData);
        }).catch(err => {
            //console.log(err)
            //return err;
            reject(err);
        });
    });

}
