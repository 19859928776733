import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";

import ViewArrayIcon from '@material-ui/icons/ViewArray';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FeedbackIcon from '@material-ui/icons/Feedback';
import CommentIcon from '@material-ui/icons/Comment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { FeedbackDialog } from '../feedback/FeedbackDialog';


const useStyles = makeStyles((theme) => ({
    lage: {
        color: "white",
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block"
        },
    },

    menuItemUser: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },

}));

const ITEM_HEIGHT = 48;

export const FeedbackBarItem = () => {
    const classes = useStyles();

    // Feedback View
    const [feedbackView, setFeedbackView] = useState(null);

    const handleClickFeedback = (event) => {
        setFeedbackView(event.currentTarget);
    };

  
    return (
        <div>
            <IconButton
                className={classes.lage}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleClickFeedback}
            >
                <FeedbackIcon fontSize="large" />
            </IconButton>

            <FeedbackBarMenu feedbackView={feedbackView} setFeedbackView={setFeedbackView}/>

            
        </div>
    )

}


export const FeedbackBarMenu = ({feedbackView, setFeedbackView}) => {

    const classes = useStyles();

    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
    const handleFeedbackDialogClose = () => {
        setFeedbackDialogOpen(false);
    };
    // Feedback View

    const openFeedbackView = Boolean(feedbackView);

    const handleCloseFeedback = () => {
        setFeedbackView(null);
    };

    const handleOpenDirectFeedback = () => {
        // Open Feeback Dialog
        setFeedbackView(null);
        setFeedbackDialogOpen(true)

    };

    const handleOpenSurvey = () => {
        // here an URL to MS Forms has to be added  
        setFeedbackView(null);
        const url = "https://www.microsoft.com/de-de/microsoft-365/online-surveys-polls-quizzes"
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    };

    return (
        <div>      

            <Menu
                id="long-user"
                className={classes.menuItemUser}
                anchorEl={feedbackView}
                keepMounted
                open={openFeedbackView}
                onClose={handleCloseFeedback}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '30ch',
                        marginTop: '-5px',

                    },
                }}
            >
                <MenuItem value={10}
                    onClick={handleOpenDirectFeedback}
                    size="small">
                    <CommentIcon className={classes.menuIcons} />
                    {"Direct Feedback"}
                </MenuItem>

                <MenuItem value={10}
                    onClick={handleOpenSurvey}
                    size="small">
                    <AssignmentIcon className={classes.menuIcons} />
                    {"Survey"}
                </MenuItem>
            </Menu>

            <FeedbackDialog handleFeedbackDialogClose={handleFeedbackDialogClose} feedbackDialogOpen={feedbackDialogOpen} />

        </div>
    )

}