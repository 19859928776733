//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env  oder // ($env:REACT_APP_BACKEND_ADRESS = "abcd") -and (npm start)
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/

const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS;
//test


// Data
// members [userId, permission]


// Create new Group //
export const CreateGroup = (name, settings, members, contextType) => {

    // Make Date
    const d = new Date();
    const date = d.toISOString();

    if (name.trim().length === 0 || name.trim().length === 0) {
        return;
    }

    // Query CreateGroup
    let requestBody = {
        query: `
                mutation CreateGroup(
                    $name: String!, 
                    $date: String!, 
                    $settings: [String!], 
                    $members: [String!]
                    ) 
                    {
                    createGroup(groupInput: {
                        name: $name, 
                        date: $date,
                        settings: $settings, 
                        members: $members 
                    }) 
                    {
                        _id
                        name
                        members                       
                    }
                }
            `,
        variables: {
            name: name,
            date: date,
            settings: settings,
            members: members
        }
    }


    // console.log(requestBody)
    // Communication to Backend
    Communication(requestBody, contextType)
}

// Update existing Group //
export const UpdateGroup = (groupId, name, members, contextType) => {
    if (name.trim().length === 0 || name.trim().length === 0) {
        return;
    }

    // Query CreateGroup
    let requestBody = {
        query: `
                mutation UpdateGroup(
                    $name: String!, 
                    $members: [String!], 
                    $groupId: ID!
                ) {
                    updateGroup(
                        groupId:$groupId, 
                        groupInput: {
                            name: $name, 
                            members: $members 
                    }) {
                        _id
                        name
                        members
                    }
                }
            `,
        variables: {
            groupId: groupId,
            name: name,
            members: members,
        }
    }

    console.log(requestBody)
    // Communication to Backend
    Communication(requestBody, contextType)
}


// Communication with backend
const Communication = (requestBody, contextType) => {

    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + contextType.token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        console.log("resData")
        console.log(resData)

        if (resData.data.createGroup._id) {
            contextType.login(
                contextType.token, // keep existing token
                contextType.userId, // keep existing userId
                contextType.tokenExpiration,
                contextType.permission, // keep existing permission
                resData.data.createGroup.members,// update only groupMember
                resData.data.createGroup._id, // update only groupId
            )
        }
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });
}


// Get all Group //
export const getGroups = (setGroups) => {

    // Queries Login 
    let requestBody = {
        query: `
            query {
                groups{
                    _id
                    name
                    settings
                    members
                }
            }
         `
    }

    // Communication to Backend
    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        setGroups(resData.data.groups)
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}


// Get Group by id //
export const GetGroupById = async (groupId, setGroup) => {

    // Query groupById
    let requestBody = {
        query: `
                mutation groupById($groupId: ID!) {
                    groupById(groupId:$groupId) {
                        _id
                        name
                        settings
                        members
                    }
                }
            `,
        variables: {
            groupId: groupId
        }
    }

    const response = await fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();

    if (data && data.data && data.data.groupById) {
        setGroup(data.data.groupById);
        return data.data.groupById;
    }

    return null;
}

// Delete a Group! Warning!!! 
export const deleteGroup = (groupId) => {

    if (groupId.trim().groupId === 0) {
        return;
    }

    // Query CreateGroup
    let requestBody = {
        query: `
                mutation DeleteGroup($groupId: ID!) {
                    deleteGroup(groupId: $groupId) {
                        _id
                        name
                    }
                }
            `,
        variables: {
            groupId: groupId,
        }
    }

    // Communication to Backend
    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}
