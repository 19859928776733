import { AppBar, makeStyles, Toolbar, Typography, alpha, Fab, Drawer, Container, MenuItem, IconButton, Menu, Divider } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { ReactComponent as Logo } from '../../logo.svg';
import { ReactComponent as LogoText } from '../../logoText.svg';
import { Fragment, useContext, useState } from 'react';
import { BarItems } from './BarItems';
import ViewTermDomainSpaceSB from '../basicComponents/ViewTermDomainSpaceSB';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import authContext from '../../context/authContext';
import GroupIcon from '@material-ui/icons/Group';
import MailIcon from '@material-ui/icons/Mail';
import { GetUserById } from '../../graphqlCommunication/AuthInterface';
import UserAvatar from './UserAvatar';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FeedbackIcon from '@material-ui/icons/Feedback';
import CommentIcon from '@material-ui/icons/Comment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { FeedbackDialog } from '../feedback/FeedbackDialog';
import { FeedbackBarItem } from './FeedbackBarItem';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(3),
        },
    },
    lage: {
        color: "white",
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block"
        },
    },
    small: {
        color: "white",
        display: "block",
        [theme.breakpoints.up("md")]: {
            display: "none"
        },
    },
    search: {
        display: "flex",
        color: "white",
        alignItems: "center",
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        borderRadius: theme.shape.borderRadius,
        width: "50%",

        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    },
    input: {
        color: "white",
        marginLeft: theme.spacing(1),
    },
    icons: {
        display: "flex",
        color: "white",
        alignItems: "center",
    },
    badge: {
        marginRight: theme.spacing(2),
    },
    menu: {
        marginRight: theme.spacing(1),
    },
    menuIcons: {
        marginRight: theme.spacing(1),
    },
    fabNav: {
        background: "none",
        boxShadow: "none",
        color: theme.palette.common.white,
    },
    container: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    headerContainer: {
        backgroundColor: theme.palette.primary.main,
        height: "54px",
    },
    formControl: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        width: 65,
    },
    textIcon: {
        paddingLeft: theme.spacing(1),
    },
    menuItemUser: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    input: {
        minWidth: 160,
        maxWidth: 240,
        background: 'white',

    },

}));


const emptyForm = {
    domain: [],
}


export const Navbar = ({ handleLogout, setViewSet, setDomainMap, domainMap, domainSpaceDefinition, setDomainSpaceDefinition }) => {
    const classes = useStyles();
    const contextType = useContext(authContext);
    const [user, setUser] = useState(emptyForm);
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
    const handleFeedbackDialogClose = () => {
        setFeedbackDialogOpen(false);
    };


    const [data, setData] = useState(emptyForm);

    const handleChange = (event, newValue) => {

    };

    const handleButtonLogout = () => {
        handleLogout()
    }


    // View constelations
    const handleButtonViewTwo = () => {
        setViewSet([5, 0, 6])
    }

    const handleButtonView3 = () => {
        setViewSet([2, 6, 3])
    }

    const handleButtonViewFlex = () => {
        setViewSet([-1, 6, 3])
    }

    const handleButtonGraphOnly = () => {
        setViewSet([0, 11, 0])
    }

    const [dawerState, setDawerState] = useState(false);

    // Toggle Drawer
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDawerState(open);
    };

    // View Menue
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    // User View
    const [userView, setUserView] = useState(null);
    const openUserView = Boolean(userView);

    const handleClickUser = (event) => {
        GetUserById(contextType.userId, setUser);
        setUserView(event.currentTarget);
    };

    const handleCloseUser = () => {
        setUserView(null);
    };

    // Feedback View
    const [feedbackView, setFeedbackView] = useState(null);
    const openFeedbackView = Boolean(feedbackView);

    const handleClickFeedback = (event) => {
        setFeedbackView(event.currentTarget);
    };

    const handleCloseFeedback = () => {
        setFeedbackView(null);
    };

    const handleOpenDirectFeedback = () => {
        // Open Feeback Dialog
        setFeedbackDialogOpen(true)

    };

    const handleOpenSurvey = () => {
        // here an URL to MS Forms has to be added  
        const url = "https://www.microsoft.com/de-de/microsoft-365/online-surveys-polls-quizzes"
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    };

    const ITEM_HEIGHT = 48;

    return (
        <div>
            <AppBar >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logo}>
                        <Typography variant="h4" className={classes.lage}>
                            <Logo />
                        </Typography>
                        <Typography variant="h6" className={classes.small}>
                            <div className={classes.icons}>
                                <Fab
                                    className={classes.fab}
                                    onClick={toggleDrawer(true)}
                                    size="small"
                                >
                                    <MenuIcon />
                                </Fab>
                                <LogoText className={classes.textIcon} />
                            </div>
                        </Typography>
                    </div>
                    {/* 
                    <div className={classes.search}>
                        <Search></Search>
                        <InputBase className={classes.input} placeholder="Search..."></InputBase>
                    </div>
                    */}
                    <div className={classes.icons}>

                        {/* More Button for View Set */}
                        <ViewTermDomainSpaceSB setDomainMap={setDomainMap} domainMap={domainMap} domainSpaceDefinition={domainSpaceDefinition} setDomainSpaceDefinition={setDomainSpaceDefinition} />

                        <IconButton
                            className={classes.lage}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleClick}
                        >
                            {/* <MoreHorizIcon  /> */}
                            <ViewArrayIcon fontSize="large" />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem value={10} onClick={handleButtonViewTwo}>
                                <LooksTwoIcon className={classes.menuIcons} />
                                Term & Detail
                            </MenuItem>

                            <MenuItem value={20} onClick={handleButtonView3}>
                                <Looks3Icon className={classes.menuIcons} />
                                View All
                            </MenuItem>

                            <MenuItem value={30} onClick={handleButtonViewFlex}>
                                <ViewColumnIcon className={classes.menuIcons} />
                                All Fexible
                            </MenuItem>
                            <MenuItem value={40} onClick={handleButtonGraphOnly}>
                                <LooksOneIcon className={classes.menuIcons} />
                                Graph only
                            </MenuItem>
                        </Menu>

                        {/*  User Feedback */}

                        <FeedbackBarItem />

                        {/*  User Menu */}
                        <IconButton
                            className={classes.lage}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleClickUser}
                        >
                            <AccountCircleIcon fontSize="large" />
                        </IconButton>

                        <Menu
                            id="long-user"
                            className={classes.menuItemUser}
                            anchorEl={userView}
                            keepMounted
                            open={openUserView}
                            onClose={handleCloseUser}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '30ch',
                                    marginTop: '-5px',

                                },
                            }}
                        >
                            <MenuItem value={10} className={classes.menuItemUser}>
                                <UserAvatar user={user} />
                                <Typography variant="h6" className={classes.textIcon}> {"   " + user.name}</Typography>
                            </MenuItem>

                            <Divider />

                            <MenuItem value={10}>
                                <MailIcon className={classes.menuIcons} />
                                {user.email}
                            </MenuItem>

                            <MenuItem value={10}>
                                <GroupIcon className={classes.menuIcons} />
                                {"" + contextType.group.name}
                            </MenuItem>

                            <Divider />

                            <MenuItem value={10}
                                onClick={handleButtonLogout}
                                size="small">
                                <ExitToAppIcon className={classes.menuIcons} />
                                {" Sign out"}
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>

            <Fragment>
                <Drawer className={classes.drawer} anchor={'left'} open={dawerState} onClose={toggleDrawer(false)}>
                    <Container className={classes.headerContainer} />
                    <Container className={classes.container}>
                        <BarItems setDawerState={setDawerState} />
                    </Container>
                </Drawer>
            </Fragment>

            <FeedbackDialog handleFeedbackDialogClose={handleFeedbackDialogClose} feedbackDialogOpen={feedbackDialogOpen} />

        </div>
    );
}

