//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

import ErrorSnackBar from "../components/basicComponents/SnackBarError";
import { connectToBackend } from "./AuthInterface";

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS;


///////////////////
// Get all Items //
export const GetAllFeedback = (setFeedbackArray, token) => {

    // Query
    const requestBody = {
        query: `
                query {
                    feedbacks{
                        _id
                        name
                        description
                        date   
                        type
                        creator {
                            email
                        }                          
                    }
                }
            `
    }

    // Communication with Backend
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const feedbacks = resData.data.feedbacks;
        setFeedbackArray(feedbacks)
        //console.log(feedbacks)
    }).catch(err => {
        //console.log(err)
    });
}

///////////////////////////
// Delete Feedback by ID //
export const DeleteFeedback = (feedbackId, setFeedbackArray, feedbackArray, token) => {

    // Query
    const requestBody = {
        query: `
                mutation DeleteFeedback ($id: ID!){
                    deleteFeedback(feedbackId:$id){
                        name
                        _id
                    }
                }
            `,
        variables: {
            id: feedbackId
        }
    }

    // Functuion to Remove Feedback from FeedbackArray
    const handelResData = (resData) => {
        const updateFeedbacks = feedbackArray.filter(feedback => {
            if (feedback._id === feedbackId) {
                //console.log("Remove Feedback form List:" + feedback.name)
            }
            return feedback._id !== feedbackId;
        })
        setFeedbackArray(updateFeedbacks)
    }

   // Communicate with Backend
   connectToBackend();

   return new Promise((resolve, reject) => {

       // Communication with Backend
       fetch(BACKEND_ADRESS, {
           method: 'POST',
           body: JSON.stringify(requestBody),
           headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + token,
           }
       }).then(res => {
           if (res.status !== 200 && res.status !== 201) { 
               throw new Error('Failed')
           }
           return res.json();
       }).then(resData => {
           handelResData(resData);
           resolve(resData);
       }).catch(err => {
           reject(err);
       });
   });
}


///////////////////////
// Update Feedback by ID //
export const UpdateFeedback = (feedbackItem, token) => {

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                    mutation UpdateFeedback(
                        $feedbackId: ID!, 
                        $name: String!, 
                        $description: String!, 
                        $date: String!, 
                    )
                    {
                        updateFeedback(feedbackId: $feedbackId  feedbackInput:{
                                name: $name, 
                                description: $description, 
                                date: $date, 
                            }) 
                            {
                                _id
                                name
                                description
                                date
                                creator{
                                    _id
                                }
                        }
                    }
                `,
        variables: {
            name: feedbackItem.name,          
            date: feedbackItem.date,
            description: feedbackItem.description,
            feedbackId: feedbackItem._id,           
        }
    }

    // Functuion to update
    const handelResData = (resData) => {
        //setFeedbackItem(feedbackItem)
    }

    // Communicate with Backend
    connectToBackend();

    return new Promise((resolve, reject) => {

        // Communication with Backend
        fetch(BACKEND_ADRESS, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) { 
                throw new Error('Failed')
            }
            return res.json();
        }).then(resData => {
            handelResData(resData);
            resolve(resData);
        }).catch(err => {
            reject(err);
        });
    });

}

/////////////////////
// Create Feedback // 
export const CreateFeedback = (feedbackItem,token) => {


    // Queries Login or CreateUser
    const requestBody = {
        query: `
                mutation CreateFeedback( 
                    $name: String!, 
                    $description: String!, 
                    $date: String!, 
                    )
                    {
                    createFeedback(feedbackInput: {
                        name: $name, 
                        description: $description, 
                        date: $date, 
                    }) 
                    {
                        _id
                        name
                        description
                        creator{
                            _id
                            email
                        }
                    }
                }
            `,
        variables: {
            name: feedbackItem.name,
            date: feedbackItem.date,
            description: feedbackItem.description,            
        }
    }

    // Functuion to Add new Feedback to FeedbackArray
    const handelResData = (resData) => {
        if (resData.data.createFeedback) {
            /* setFeedbackArray(prevState => {
                return [
                    ...prevState,
                    {
                        ...resData.data.createFeedback
                    }
                ];
            }); */
        }
    }

    // Communicate with Backend
    //connectToBackend(); 
    return new Promise((resolve, reject) => {

        // Communication with Backend
        fetch(BACKEND_ADRESS, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) { 
                throw new Error('Failed')
            }
            return res.json();
        }).then(resData => {
            handelResData(resData);
            resolve(resData);
        }).catch(err => {
            reject(err);
        });
    });

}
