import { useState, Fragment, useContext, useEffect, useRef } from 'react';
import AuthContext from '../../context/authContext';
import clsx from 'clsx';
//import clsx from 'clsx';

// Import Material 
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


///////////// Hier weiter Quill muss wieder eingebaut werden!

//import ReactDOM from 'react-dom';
//import { Editor, EditorState } from 'draft-js';

//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';



//Import Components
import { UpdateTerm } from '../../graphqlCommunication/TermsInterface';
import { SelectTermType } from '../basicComponents/SelectTermType';
import { SelectTermDomainSpace } from '../basicComponents/SelectTermDomainSpace';
import { theme } from '../../theme';
import SnackBarSuccess from '../basicComponents/SnackBarSuccess';
import SnackBarError from '../basicComponents/SnackBarError';


const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 160,
        maxWidth: 240,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const EditDialog = ({ termItem, setTermItem, handleEditDialogClose, editDialogOpen, domainSpaceDefinition, termTypeMap, setTermArray, termArray }) => {
    const classes = useStyles();
    const contextType = useContext(AuthContext);
    const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
      });

    if (!termItem.images) {
        termItem.images = []
    }

    /* //console.log("-------- DEBUG termItem -------------")
    //console.log(termItem) */

    const [values, setValues] = useState({
        name: termItem.name,
        description: termItem.description,
        abbreviation: termItem.abbreviation,
        type: termItem.type,
        images: termItem.images[0],
        source: termItem.source,
        domain: termItem.domain[0],
        views: termItem.views[0]
    });

    /*     //console.log("termTypeMap")
        //console.log(termTypeMap) */
    // If termItem will change the values will be set again
    useEffect(() => {
        setValues({
            name: termItem.name,
            description: termItem.description,
            abbreviation: termItem.abbreviation,
            type: termItem.type,
            images: termItem.images[0],
            source: termItem.source,
            domain: termItem.domain[0],
            views: termItem.views[0]
        })
        setTextValue(termItem.description)
    }, [termItem]);


    const [textValue, setTextValue] = useState(values.description);

    const handleClose = () => {
        handleEditDialogClose();
    };


    const handleSave = () => {

        setSuccess(false);
        setLoading(true);

        // Save data 
        if (values.name !== undefined) {
            termItem.name = values.name;
        }
        if (values.abbreviation !== undefined) {
            termItem.abbreviation = values.abbreviation;
        }
        if (values.description !== undefined) {
            termItem.description = textValue;
        }
        if (values.type !== undefined) {
            termItem.type = values.type;
        }
        if (values.images !== undefined) {
            termItem.images = [values.images];
        }
        if (values.source !== undefined) {
            termItem.source = values.source;
        }
        if (values.domain !== undefined) {
            termItem.domain = [values.domain];
        }
        if (!values.date || values.date === "") {
            termItem.date = new Date().toISOString()
        }

        console.log("termArray")
        console.log(termArray)
        console.log("termItem")
        console.log(termItem)
        console.log(termItem.domain[0])
        // Cummunication with Backend   

        termArray = termArray.map(term => {
            if (term._id === termItem._id) {
                console.log(term.name)
                console.log(termItem.domain[0])
                term = termItem
            }
            return term
        })

        setTermArray(termArray)

        console.log("termArray2")
        console.log(termArray)


        UpdateTerm(termItem, contextType.token)
            .then(result => {
                console.log('Term update successfully:', result);
                handleClose();
                setOpenAlert(true)
                setTermItem(termItem)


            })
            .catch(error => {
                console.error('Error update term:', error);
                setSuccess(true);
                setLoading(false);
                setOpenErrorAlert(true)
            });
    };

    const handleChange = (event, newValue) => {
        let { value, name } = event.target;
        if (name === "check") {
            const { checked } = event.target;
            value = checked;
        }
        ///////////// Hier weiter das muss wieder durch Quill ersetzt werden
        if (name === "description") {
            setTextValue(value)
        }
        /////////////////
        console.log("name: " + name + " value: " + value)
        setValues(values => {
            return {
                ...values,
                [name]: value
            };
        });
    };


    // Editor Settings
    const modules = {
        toolbar: [
            /* [{ header: [1, 2, 3, 4, 5, 6, false] }], */
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: [] }],
            /* [{ font: [] }], */
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [{
                color: [theme.palette.text.primary,
                theme.palette.primary.light,
                theme.palette.primary.main,
                theme.palette.primary.dark,
                theme.palette.secondary.light,
                theme.palette.secondary.main,
                theme.palette.secondary.dark,
                theme.palette.secondary.contrastText]
            }],
            /* [{ background: ["red", "#785412"] }] */
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align",
        "size",
        "font"
    ];

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false)
    }

    const handleCloseErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false)
    }


    return (
        <Fragment>
            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth="true">
                <DialogTitle id="form-dialog-title">Edit Term</DialogTitle>
                <DialogContent>
                    <TextField
                        className={classes.margin}
                        id="name"
                        label="Name"
                        fullWidth
                        defaultValue={termItem.name}
                        name="name"
                        variant="outlined"
                        value={values.name}
                        onChange={event => handleChange(event, values.name)}
                    />
                    <TextField
                        className={classes.margin}
                        id="abbreviation"
                        label="Abbreviation"
                        defaultValue={termItem.abbreviation}
                        name="abbreviation"
                        variant="outlined"
                        value={values.abbreviation}
                        size="small"
                        onChange={event => handleChange(event, values.abbreviation)}
                    />
                    <SelectTermType
                        data={values}
                        onChange={handleChange}
                        classes={classes}
                        termTypeMap={termTypeMap} />
                    <SelectTermDomainSpace
                        data={values}
                        onChange={handleChange}
                        classes={classes}
                        domainSpaceDefinition={domainSpaceDefinition} />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Description"
                        fullWidth
                        defaultValue={termItem.description}
                        multiline
                        rows={10}
                        name="description"
                        variant="outlined"
                        value={values.description}
                        onChange={event => handleChange(event, values.description)}
                    />

                    {/*                     <ReactQuill
                        theme="snow"
                        value={textValue}
                        onChange={setTextValue}
                        modules={modules}
                        formats={formats} /> */}


                    <TextField
                        className={classes.margin}
                        id="images"
                        label="Image URL"
                        fullWidth
                        defaultValue={termItem.images[0]}
                        name="images"
                        variant="outlined"
                        value={values.images}
                        onChange={event => handleChange(event, values.images)}
                    />
                    <TextField
                        className={classes.margin}
                        id="source"
                        label="source"
                        fullWidth
                        defaultValue={termItem.source}
                        name="source"
                        variant="outlined"
                        value={values.source}
                        onChange={event => handleChange(event, values.source)}
                    />
                    {termItem.creator &&
                        <DialogContentText>
                            {"id: " + termItem._id + " | Creator: " + termItem.creator.email}
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            disabled={loading}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>

            <SnackBarSuccess open={openAlert} handleClose={handleCloseAlert} message="Term has bin updated!" />
            <SnackBarError open={openErrorAlert} handleClose={handleCloseErrorAlert} message="Term has not bin updates!" />

        </Fragment>
    );
}